/**
*
* Global Utility bar
*
*
**/
$utilityBar_height: 39px;

.utility-bar {

    @extend %gradient_90;
    //Search bar and tools
    .search-bar {
        height: 0;
        width: 100%;
        overflow: hidden;
        background-color: #30383b;
    }

    input.global-search {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 15px;
        background-color: #30383b;
        border: none;
        font-family: $universalLight;
        font-size: 18px;
        line-height: 36px;
        color: white;
        //Placeholder text
        &::-webkit-input-placeholder {
            color: white;
            font-style: normal;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: white;
            font-style: normal;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: white;
            font-style: normal;
        }

        &:-ms-input-placeholder {
            color: white;
            font-style: normal;
        }

        &:focus {
            background: none;
            border: none;
        }
    }

    .links-row {
        display: none;
    }
    //Desktop
    @media #{$sm} {
        //MIN - 768
        border-bottom: 1px solid #d7dbdd;

        input.global-search {
            margin-top: 35px;
            font-size: 24px;
        }
        //Links Row
        .links-row {
            display: block;
        }

        .utility-links {
            height: $utilityBar_height;
            float: right;
            position: relative;
            // JMJS: This is all quite horrible but I couldn't see a better way in the timescale I had
            // Everything is coded with so much specificity.
            & .sub-nav {
                display: none;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 50px;
                z-index: 150;
                width: 188px;
                padding: 20px 0 5px;
                background-color: $green;
                border: none;
                box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);




                li {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                }

                a {
                    display: block;
                    float: none;
                    padding: 0 20px;
                    border-right: none !important;
                    font-family: $universalLight;
                    font-size: 16px;
                    color: #fff;
                    line-height: 38px;
                    white-space: nowrap;

                    &:hover {
                        background-color: #30383b;
                        text-decoration: none;
                    }
                }
            }

            .header-email, .header-telephone {
                position:relative;
                top:3px;
                font-size: 30px;
            }
        }

        .utility-nav {
            position: relative;
            float: left;
        }

        .efic-direct,
        a {
            display: block;
            float: left;
            height: $utilityBar_height;
            color: white;
            font-size: 14px;
            font-family: $universalLight;
            line-height: $utilityBar_height;
        }

        .efic-direct {
            position: relative;
            background-color: $green;
            padding-right: 44px;
            padding-left: 20px;

            &:after {
                position: absolute;
                top: 50%; 
                transform: translateY(-50%);
                right: 16px;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 4px 0 4px;
                border-color: #fff transparent transparent transparent;
            }
            
            &.active:after {
                border-width: 0 4px 8px 4px;
                border-color: transparent transparent #fff transparent;
            }
        }

        .contact-us {
            position: relative;
            padding-left: 25px;
            padding-right:26px;
            .icon {
                color: white;
                font-size: 25px;
                position: absolute;
                top: 3px;
                left: 0;
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .email-address {
          padding-left:2px;
          padding-right:19px;
        }


        .phone-number {
          padding-right: 25px;
        }

        .header-icon {
          font-size:14px;
          position:relative;
          top:2px;
          right:4px;
        }




        .btn-search-trigger {
            width: 60px;
            padding: 0;
            background-color: #30383b;
            position: relative;
            text-align: center;
            text-decoration: none;

            .copy {
                display: none;
            }

            .icon {
                color: white;
                font-size: 29px;
                position: relative;
                top: 3px;

            }
            //Icons
            .icon-close {
                display: none;
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &.active {
                .icon-search {
                    display: none;
                }

                .icon-close {
                    display: block;
                }
            }
        }
    }


    @media #{$md} {
        //MIN - 992
        display: block;
        //Links Row
        .utility-links {
            padding-right: 24px;
        }
    }
}

// Left links basic styles.
.left-links {
    text-align: right;

    & li + li {
        margin-top: 16px;
    }
}

.mod-events-list {

    .left-links h5 {
        text-align: right;
        margin-right: 10px;
        color: black;
        font-size: 21px;
    }

    .left-links h6 {
        text-align: right;
        color: #afca0b;
        margin-right: 10px;
        margin-top: 20px;
        line-height: 18px;
        font-weight: bold;
        margin-bottom: 16px;
        font-size: 18px;
    }
}

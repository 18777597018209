/**
*
* Product page, recommended product tile
*
**/
.recommended-product-tile {
    /*GUIDES*/
    /**/
    //background-image:url("#{$img-url}/_GUIDES/recommended-product-tile.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    //margin-top: 70px;
    //margin-bottom: 100px;
    /**/
    @include clearfix;
    background-color: #e9e9e9;
    padding: 20px;

    .promo-tiles {
        margin-top: 0;
    }
    //Left column copy
    .text-block {
        width: 100%;
        padding: 0 20px 0px 10px;
        color: black;

        .title {
            @extend %Universal_65;
            font-size: 22px;
            margin-bottom: 25px;
        }

        .description {
            @extend %Universal_45;
            margin-bottom: 20px;
            font-size: 18px;
            font-style: italic;
            line-height: 25px;
        }

        .recommended {
            @extend %Universal_45;
            font-size: 15px;
        }
    }
    //Right column promo tile
    .tile {
        height: 300px;
        width: 100%;
        position: relative;
        overflow: hidden;
        border: solid 1px #d7dbdd;

        .img {
            img {
                width: 100%;
            }
        }


        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 20px;
            left: 0;
            bottom: -184px;
            background-color: white;
            border-top: solid 1px #d7dbdd;
            transition: bottom .3s;

            .title {
                @extend %Universal_55;
                margin-top: 0;
                font-size: 20px;
                color: #2f383a;
                line-height: 1.2;
            }
        }

        .copy-block {
            @extend %Universal_45;
            opacity: 0; //Hidden by default
            font-size: 14px;
            color: #2f383a;
            transition: opacity .3s;
        }

        .link-row {
            margin-top: 20px;
            margin-bottom: 40px;
            text-align: right !important;

            a {
                @extend %Universal_55;
                font-weight: bold;
                font-size: 16px;
                color: #30383b;
                text-decoration: underline;
            }
        }

        &:hover {
            .title {
                color: $green;
            }

            .content {
                bottom: 1px;
                margin-bottom: -1px;
            }

            .copy-block {
                opacity: 1;
            }
        }
    }


    @media #{$sm} {
        //MIN - 768
        //Left column copy
        .text-block {
            width: 100%;
            width: calc(100% - 220px);
            float: left;
        }

        .tile {
            width: 220px;
            float: left;
        }
    }


    @media #{$md} {
        //MIN - 992
    }
}

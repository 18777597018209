/* EFIC > Modules > Social Twitter Feed | Deepend.com.au (c) 2015 | Author: @elray72 */

#homeTwitterFeed,
.mod-twitter-feed {

    ul {
        li {
            padding: 5px;
            background: #fff;
            position: relative;
        }
    }

    .user {
        display: block;
        height: 35px;


        a {
            //display: block;
            height: inherit;
            line-height: 20px;

            img {
                width: auto;
                display: block;
                height: inherit;
                float: left;
                margin-right: 5px;
            }
        }
    }

    .timePosted {
        position: absolute;
        top: 10px;
        left: 45px;
        font-size: 10px;
        color: #aaa;
    }

    .tweet {
        overflow: hidden;
        line-height: 1.4em;
        margin: 5px 0;

        a {
            color: $green;
        }
    }

    .interact {
        margin: 0;
        text-align: right;

        a {
            margin: 0 5px;
            color: $green;
        }
    }

    [data-scribe="element:verified_badge"] {
        display: inline-block;
    }
}

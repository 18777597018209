/**
*
* Large feature tiles with video, used on homepage primarily below full width
* feature row.
* Video on left, copy and CTA on right
*
**/
.feature-video-tile {
    background-color: white;
    border: solid 1px #d7dbdd;
    width: 100%;
    margin-bottom:53px;
    position: relative;

    .video-block {
        height: auto;
        max-width: 100%;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        // margin:0px 0px 0 0px !important;
        // margin: 15px 15px 0 15px;

        iframe,
        object,
        embed,
        video {
            background-color: transparent;
            border: 0;
            height: 100%;
            left: 0;
            margin: 0;
            outline: none;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .content-block {
        padding: 20px;
        margin-bottom: 60px;
    }

    .title {
        @extend %Universal_45;
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
        font-size: 21px;
        font-weight: bold;
        color: #2f383a;
        line-height: 1.2;
    }

    p {
        @extend %Universal_45;
        font-size: 16px;
        line-height: 24px;
        color: #2f383a;
    }

    .btn-cta {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }

    //Desktop
    @media #{$sm} {
        height: 300px;
        //margin-bottom: 164px;
        .video-block {
            margin: 15px 0 0 15px;
        }

        .content-block {
            padding: 25px 20px 25px 0px;
        }
    }

    @media #{$md} {
        height: 288px;
    }
}

/* Modules > Lists | Deepend.com.au (c) 2014 */

// Base list
.list {

    margin: 0;
    padding: 0;
    list-style-type: none;

    .item {

        background: #FFFFFF;
        position: relative;
        border: 1px solid #ddd;
        padding: 10px;
        list-style-type: none;

        a {
            color: inherit;
            text-decoration: none;
        }
    }
}

// Horizontal list
.list-h {

    overflow: hidden;
    margin: 0;

    .item,
    .item:first-child,
    .item:last-child {
        border-radius: 0;
    }

    .item {
        float: left;
    }
}

// Vertical list
.list-v {

}
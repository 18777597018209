
.btn-toggle-panel {
    cursor: pointer;
}

.toggle-panel {

    &.collapsed {
        display: none;
    }

    &.expand {

    }
}


.btn-global {
	@extend %Universal_45;
	background-color: #52ae32;
	display: inline-block;
	font-size: 24px;
	color: white;
	padding: 10px 15px;
	border: solid 1px white;

	&:hover {
		color: white;
		text-decoration: none;
	}
}

.link-button-row {
	margin: 10px 0;
}
/**
*
* Only apply to SBEL Eligibility Criteria page at moment
*
**/
.product-eligibility-criteria {

    @media only screen and (max-width : $screen-xs-max) {
        //Mobile only

        table {
            background-color: $offWhite;
        }

        th, td {
            display: block;
            width: 100%;
            background-color: transparent;

            &.alt-bg {
                background-color: transparent;
            }

            &:first-child {
                border-top: 1px solid $table-border-color;
            }

            &:not(:first-child) {
                border-top: 0 none;
                border-left: 0 none;
            }
        }

        th {
            &:first-child {
                display: none;
            }
        }
    }
}
.eligibility-criteria {
    
    img {
        
        @media screen and (min-width: 768px) {
            max-height: 40px; 
            max-width: 40px;
        }
        @media screen and (max-width: 468px){
            float: right;
            max-height: 25px; 
            max-width: 25px;
        }
    }        
    
    div {
        @media screen and (max-width: 468px){
            display: inline-block;
        }
    }
}


/**
*
* Tab Nav
* Scrollable on mobile view
*
**/

.tab-nav {
    position: relative;
    margin: 0 (-($grid-gutter-width / 2)) 40px;
    height: 60px;
    overflow: hidden;

    .tab-nav-inner {
        position: relative;
        display: block;
        padding-bottom: 15px;
        overflow-x: auto;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 35px;
        height: 60px;
        background: -moz-linear-gradient(left,  rgba($offWhite, 0) 0%, rgba($offWhite,1) 100%);
        background: -webkit-linear-gradient(left,  rgba($offWhite, 0) 0%, rgba($offWhite,1) 100%);
        background: linear-gradient(to right,  rgba($offWhite, 0) 0%, rgba($offWhite,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f3f3f3', endColorstr='#f3f3f3',GradientType=1 );
    }

    ul {
        @include clearfix;
        min-width: $screen-sm;
    }

    a {
        position: absolute;
        display: block;
        width: 100%;
        min-height: 100%;
        white-space: normal;
        border-right: 1px solid $white;
    }

    h6 {
        @extend %Universal_65;
        position: absolute;
        display: block;
        top: 50%;
        width: 100%;
        margin: 0;
        padding: 0 25px;
        color: #2d3a41;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        transform: translateY(-50%);
    }

    li {
        position: relative;
        display: block;
        float: left;
        width: 20%;
        min-height: 60px;
        line-height: 60px;
        background-color: $offWhite;

        &:hover {
            background-color: $altGrey;

            h6 {
                color: $white;
            }
        }

        &:last-child {
            border-right: 0 none;
        }

        &.active {
            h6 {
                color: $green;
            }
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
        margin-bottom: 70px;
        overflow: visible;

        &:after {
            display: none;
        }

        .tab-nav-inner {
            overflow: visible;
        }

        h6 {
            font-size: 16px;
        }

        li {
            min-height: 70px;
            line-height: 70px;

            &.active {
                background-color: $green;

                a {
                    z-index: 1;
                }

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 50%;
                    bottom: 0;
                    width: 30px;
                    height: 30px;
                    background-color: $green;
                    transform: translate(-50%, 50%) rotate(45deg);
                    z-index: 0;
                }

                h6 {
                    color: $white;
                }
            }
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}

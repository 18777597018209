/* EFIC > Tiles > Promo Tile | Deepend.com.au (c) 2015 */

.promo-tile-container {
    margin-bottom: 40px;

    .case-tile.tall-tile {
        width: 189px;
        float: right;
        position: relative;
        right: -2px;

        img {
            margin-left: -3px;
        }

        .tile-button {
            left: 30px;
        }
    }

    .tall-tile-content {
        width: 153px;
    }
}
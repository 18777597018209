/**
*
* Product page, 3 col promo tiles
*
**/
.promo-tiles {
    margin-top: 40px;

    &.side-promo{
        margin-top: 6px;
    }

    //Intro copy block
    .intro-copy {
        width: 100%;
        margin-bottom: 10px;

        h2 {
            @extend %Universal_45;
            font-weight: bold;
            font-size: 28px !important;
            color: #30383b;
            margin: 0;
            margin-bottom: 10px;
            padding: 0;
        }

        p {
            @extend %Universal_45;
            font-size: 16px;
            color: #30383b;
            margin: 0;
            padding: 0;
        }
    }

    //Common Tile styles
    .tile {
        height: 340px;
        position: relative;
        overflow: hidden;
        border: solid 1px #d7dbdd;
        margin-bottom: 20px;

        a {
            color: black;
            text-decoration: none;

            &:hover {
                color: $green;
            }
        }
    }



    //Tile Variants - Promo style, image and content block
    .tile.promo {
        .img {
            img {
                width: 100%;
            }
        }


        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 20px;
            left: 0;
            bottom: -227px;
            background-color: white;
            border-top: solid 1px #d7dbdd;
            transition: bottom .3s;

            .title {
                @extend %Universal_55;
                margin-top: 0;
                font-size: 20px;
                color: #2f383a;
                line-height: 1.2
            }
        }

        .copy-block {
            @extend %Universal_45;
            opacity: 0; //Hidden by default
            font-size: 14px;
            color: #2f383a;
            transition: opacity .3s;

            ul, ol {
                padding: 0 20px;
                list-style-type: initial;
            }
        }

        .link-row {
            margin-top: 20px;
            margin-bottom: 40px;
            text-align: right;
            a {
                @extend %Universal_55;
                font-weight: bold;
                font-size: 16px;
                color: #30383b;
                text-decoration: underline;
            }
        }

        &:hover {
            .title {
                color: $green;
            }

            .content{
                bottom:1px;
                margin-bottom:-1px;
            }

            .copy-block{
                opacity:1;
            }
        }
    }

    //Link block variant
    .tile.link-block {
        height: auto;
        padding: 15px 20px;
        background-color: white;

        .title {
            @extend %Universal_65;
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 20px;
            text-align: center;
        }

        .btn-cta {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            color: white;
        }
    }

    //Text link row, below all tiles
    .bottom-link-row {
        margin-top: 0px;
        margin-bottom: 67px;
        text-align: right;

        a {
            @extend %Universal_55;
            font-weight: bold;
            font-size: 16px;
            color: #30383b;
            text-decoration: underline;
            letter-spacing:1px;
        }
    }

    @media #{$sm} {
        //MIN - 768

        //Link block variant
        .tile.link-block {
            height: auto;
        }

        .btn-cta {
            height: 54px;
        }

        .tile {
          height: 300px;
        }

        .tile.promo {
          .content {
            position:absolute;
            bottom: -184px;

          }
        }
    }


    @media #{$md} {
        //MIN - 992

        //Link block variant
        .tile.link-block {
            height: 300px;
        }

        .btn-cta {
            height: auto;
        }


    }
}

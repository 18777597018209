/*
	jQuery.mmenu oncanvas CSS
*/
@import "_inc/variables";


//	Generic classes
.mm-hidden
{
	display: none !important;
}


//	Container
.mm-wrapper
{
	overflow-x: hidden;
	position: relative;
}


//	Menu
.mm-menu,
.mm-panels,
.mm-panels > .mm-panel
{
	margin: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
}
.mm-menu
{
	box-sizing: border-box;
	background: inherit;
	display: block;
	overflow: hidden;
	padding: 0;
}


//	Panels
.mm-panel
{
	-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
	-moz-transition: -moz-transform $mm_transitionDuration $mm_transitionFunction;
	-ms-transition: -ms-transform $mm_transitionDuration $mm_transitionFunction;
	-o-transition: -o-transform $mm_transitionDuration $mm_transitionFunction;
	transition: transform $mm_transitionDuration $mm_transitionFunction;

	@include mm_webkit_prefix( 'transform', translate3d( 100%, 0, 0 ) );

	&.mm-opened
	{
		@include mm_webkit_prefix( 'transform', translate3d( 0%, 0, 0 ) );
	}
	&.mm-subopened
	{
		@include mm_webkit_prefix( 'transform', translate3d( -$mm_subpanelOffset, 0, 0 ) );
	}
	&.mm-highest
	{
		z-index: 1;
	}
}

.mm-panels,
.mm-panels > .mm-panel
{
	background: inherit;
	border-color: inherit;
}
.mm-panels > .mm-panel
{
	background: inherit;
	border-color: inherit;

	-webkit-overflow-scrolling: touch;
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: auto;

	box-sizing: border-box;
	padding: 0 $mm_panelPadding;

	&.mm-hasnavbar
	{
		padding-top: $mm_navbarHeight;
	}

	//	Because padding-bottom in some browsers is ignored when a DIV is scrollable
	&:before,
	&:after
	{
		content: '';
		display: block;
		height: $mm_panelPadding;
	}
}


//	Vertical
.mm-vertical .mm-panel
{
	@include mm_webkit_prefix( 'transform', none !important );
}

.mm-vertical .mm-listview,
.mm-listview .mm-vertical
{
	.mm-panel
	{
		display: none;
		padding: $mm_padding 0 $mm_padding $mm_padding;

		.mm-listview > li:last-child:after
		{
			border-color: transparent;
		}
	}
}
.mm-vertical li.mm-opened,
li.mm-vertical.mm-opened
{
	> .mm-panel
	{
		display: block;
	}
}
.mm-vertical .mm-listview > li,
.mm-listview > li.mm-vertical
{
	> .mm-next
	{
		height: $mm_btnSize;
		bottom: auto;

		&:after
		{
			top: ( $mm_btnSize / 2 ) - 4;
			bottom: auto;
		}
	}
	&.mm-opened
	{
		> .mm-next:after
		{
			@include mm_webkit_prefix( "transform", rotate( 45deg ) );
		}
	}
}

.mm-navbar
{
	border-bottom: 1px solid;
	border-color: inherit;
	text-align: center;
	line-height: $mm_btnSize / 2;

	height: $mm_navbarHeight;
	padding: 0 $mm_btnSize;

	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	> *
	{
		display: block;
		padding: ( $mm_btnSize / 4 ) 0;
	}
	a,
	a:hover
	{
		text-decoration: none;
	}
	.mm-title
	{
		@include mm_ellipsis;
	}
	.mm-btn
	{
		box-sizing: border-box;
		width: $mm_btnSize;
		height: $mm_btnSize;
		position: absolute;
		top: 0;
		z-index: 1;

		&:first-child
		{
			padding-left: $mm_panelPadding;
			left: 0;
		}
		&:last-child
		{
			text-align: right;
			padding-right: $mm_panelPadding;
			right: 0;
		}
	}
}
.mm-panel
{
	.mm-navbar
	{
		display: none;
	}
	&.mm-hasnavbar .mm-navbar
	{
		display: block;
	}
}


//	Listviews
.mm-listview,
.mm-listview > li
{
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
}
.mm-listview
{
	font: inherit;
	font-size: $mm_fontSize;
	line-height: $mm_btnSize - ( $mm_padding * 2 );

	a,
	a:hover
	{
		text-decoration: none;
	}

	> li
	{
		position: relative;

		&,
		&:after,
		.mm-next,
		.mm-next:before
		{
			border-color: inherit;
		}

		> a,
		> span
		{
			@include mm_ellipsis;

			color: inherit;
			display: block;
			padding: $mm_listitemPadding $mm_listitemPadding $mm_listitemPadding $mm_listitemIndent;
			margin: 0;

			&.mm-arrow
			{
				padding-right: $mm_subopenWidth;
			}
		}

		&:not(.mm-divider)
		{
			@include mm_border_bottom;

			&:after
			{
				left: $mm_listitemIndent;
			}
		}
	}

	// subopen
	.mm-next
	{
		//@include mm_border_left;
		//background: rgba( 3, 2, 1, 0 );
		width: $mm_subopenWidth;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;

		+ a,
		+ span
		{
			margin-right: $mm_subopenWidth;
		}

		&.mm-fullsubopen
		{
			width: 100%;

			&:before
			{
				border-left: none;
			}

			+ a,
			+ span
			{
				padding-right: $mm_subopenWidth;
				margin-right: 0;
			}
		}
	}
}
.mm-panels > .mm-panel
{
	> .mm-listview
	{
		margin: $mm_panelPadding ( -$mm_panelPadding );
	}
	> .mm-listview:first-child,
	> .mm-navbar + .mm-listview
	{
		margin-top: -$mm_panelPadding;
	}
}


//	Inset lists
.mm-listview .mm-inset,
{
	list-style: inside disc;
	padding: 0 $mm_listitemPadding ( $mm_listitemPadding * 1.5 ) ( $mm_listitemIndent * 2 );
	margin: 0;

	> li
	{
		padding: ( $mm_listitemPadding / 2 ) 0;
	}
}


// Dividers
.mm-listview .mm-divider
{
	@include mm_ellipsis;

	font-size: $mm_dividerFontSize;
	text-transform: uppercase;
	text-indent: $mm_listitemIndent;
	line-height: $mm_dividerHeight;
}


// Spacers
.mm-listview .mm-spacer
{
	padding-top: $mm_btnSize;

	> .mm-next
	{
		top: $mm_btnSize;
	}

	&.mm-divider
	{
		padding-top: $mm_dividerHeight;
	}
}


// Arrows
//Removed - BW 11/11/15
/*
.mm-prev:before,
.mm-next:after,
.mm-arrow:after
{
	@include mm_arrow;
}
.mm-prev:before
{
	@include mm_arrow_prev;
}
.mm-next:after,
.mm-arrow:after
{
	@include mm_arrow_next;
}
*/


@include mm_colors;
/**
*
* table-listing
* 
* - Usage: 
*   Add table-listing class on ol/ul elements
*
**/
.table-listing {
    margin: 0;

    .richtext-content & {
        padding-left: 0;
    }

    > li {
        margin: 0 auto;
        padding: 22px 0 0;
        list-style: none;
        border-bottom: 1px solid $table-border-color;

        &:last-child {
            border-bottom: 0 none;
        }
    }

    .table-listing-col {
        display: inline-block;
        padding-right: 20px;
        vertical-align: middle;

        &:last-child {
            padding-right: 0;
        }

        &.step-duration {
            width: 140px;
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
    }

    @media #{$md} {
        //MIN - 992
    }
}

/* Modules > Footer | Deepend.com.au (c) 2014 | Author: Ray Ranola */

$footer-text-color: #42494b;

// Mod Styles
#footer {
    margin-top :30px;
    height: auto;
    color: $footer-text-color;
    font-size: 12px;
    padding-bottom: 15px;
    background-color: $grey;
    overflow: hidden;

    a {
        color: $footer-text-color;

        &:hover {
            color: $green;
        }
    }

    h1, h2, h3 {
        font-family: $universalBold;
        font-size: 14px;
        color: $footer-text-color;
        line-height: 14px;
        margin: 10px 0;
    }

    label {
        display: inline-block;
        width: 110px;
        clear: left;
    }

    p {
        display: block;
        font-size: inherit;
    }

    ul {

        li {
            margin-bottom: 5px;
            line-height: 1.4em;
        }
    }

    .mod-footer-contact {

        .contact-email,
        .contact-call {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1;
            vertical-align: top;
        }

        .icon-email,
        .icon-call {
            &:before {
                display: inline-block;
                line-height: 1;
                vertical-align: bottom;
            }
        }

        .address {
            margin: 0 0 10px 0;
            float: left;

            label {
                display: block;
                width: 100%;
                margin: 0 0 5px 0;
            }
        }
    }

    .mod-footer-links {

        .col {
            float: left;
        }

        .quick-links {
            width: 50%;
        }

        .other-links {
            width: 50%;
        }
    }

    .mod-social-connect {
        height: auto;
        overflow: visible;

        h6 {
            display: block;
            width: 100%;
        }

        .list-h {
            overflow: visible;
        }
    }

    @media (max-width: $screen-xs-max) {

        padding-bottom: 10px;

        .mod-footer-links {

            ul {
                li {
                    a {
                        display: inline-block;
                    }
                }
            }

            .quick-links {

                width: 100%;
                float: none;

                ul {
                    -moz-column-count: 2;
                    -moz-column-gap: 20px;
                    -webkit-column-count: 2;
                    -webkit-column-gap: 20px;
                    column-count: 2;
                    column-gap: 20px;
                }

                + .other-links {
                    margin-right: 20px;
                }
            }

            .other-links {
                width: 50%;
                float: left;
                display: block;
            }
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}
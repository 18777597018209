/**
*
* rich text content styles
* Only apply to SBEL page at moment
*
**/
//GREEEN


.richtext-content {
    margin-bottom: 35px;
    padding-left: 0;  // have to use important because _legacy.scss
    padding-right: 0;  // have to use important because _legacy.scss

    h2 {
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 32px !important; // have to use important because _ridiculous-hacks.scss
        line-height: 40px;
    }

    h4, h5 {
        margin: 0 auto 17px;
        font-family: $universalBold;
    }

    p {
        margin: 0 auto 17px;
    }

    ol, ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 15px;
        padding-bottom: 17px;
        list-style: initial;
    }

    li {
        margin-bottom: 15px;
        padding-left: 5px;
        line-height: 20px;
    }
    a {
        text-decoration:underline;
        &:hover {
            color: #000000;
        }
    }


    //Desktop
    @media #{$sm} {
        //MIN - 768

        margin-bottom: 50px;

        h2 {
            margin-top: 0;
        }


    }

    @media #{$md} {
        //MIN - 992
    }
}

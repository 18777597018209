/**
*
* Accordion module
*
**/

.accordion {
    padding-bottom: 20px;
    border-top: 1px solid $table-border-color;

    .item-heading {
        display: block;
        padding: 10px;
        cursor: pointer;

        &:hover {
            h4 {
                color: $link-hover-color;
            }
        }

        h4 {
            margin: 0;
            color: $link-color;
        }
    }

    .item-content-block {
        padding: 10px 10px 20px;

        ul, ol {
            list-style: initial;
        }

        ul {
            padding-left: 20px;            
        }        

        ul, ol {
            li {
                padding-left: 5px;
            }
        }
    }

    .accordion-item {
        border-bottom: 1px solid $table-border-color;

        &.open {
            .icon-down-arrow:before {
                @include transform(rotate(0deg));
            }
        }

        .icon-down-arrow:before {
            @include transform(rotate(-90deg));
            display: inline-block;
            line-height: 28px;
            vertical-align: top;
            transition: transform $mm_transitionDuration $mm_transitionFunction;
        }
        
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
    }

    @media #{$md} {
        //MIN - 992
    }
}

/**
*
* Modal styles override
*
**/

.modal-content {
    background-color: $modal-content-bg;
    border: 0 none;
    border-radius: 0;
}

.modal-header {
    border-bottom: 0 none;

    .close {
        position: absolute;
        top: 2px;
        right: 0;
        color: $green;
        font-size: 1.2em;
        font-weight: bold;
        cursor: pointer;
        border: 0 none;
        background: $white;

        &:hover {
            background: $link-hover-color;
        }
    }

}

.modal-title {
    color: $green;
    font-size: 28px;
    line-height: 34px;
}

.modal-body {
    @extend %Universal_55;
    padding: 0 $modal-inner-padding;
    font-size: 16px;
    line-height: 24px;

    ul, ol {
        @extend %Universal_45;
        list-style: initial;
    }

    ul {
        padding-left: 20px;            
    }        

    ul, ol {
        li {
            padding-left: 5px;
        }
    }
}

.modal-footer {
    text-align: left;
    border-top: 0 none;

    .btn-cta {
        display: inline-block;        
        padding-top: 7px;
        padding-bottom: 7px;
        text-align: center;
        border: 0 none;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }
}

@media #{$sm} {
    //MIN - 768

    .modal-footer {
        .btn-cta{
            min-width: 140px;

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 16px;
            }
        }
    }
}

@media #{$md} {
    //MIN - 992
}
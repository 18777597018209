/**
*
* Form elements
* NOTE: Forms are a mess in this site, there are several different form stylesheets / SCSS files.
* This file is intended as a temporary measure to force forms to behave for mobile elemnts,
* specifically for Countour forms.

* Beware
* Importants below
*
**/

//All form pages have 'form-page' class in template,
//Contour forms are wrapped in div with contour class
//Needed to increase specificity
.form-page {
    .contour {
        //Labels
        label.fieldLabel {
            width: 100%;
        }
        //Field types
        select,
        input,
        textarea {
            width: 100%;
        }

        input[type=submit] {
            width: 174px;
            margin: auto;
        }

        .contourField {
            @include clearfix-micro();
            margin-bottom: 20px;
        }
        //Desktop
        @media #{$sm} {
            //MIN - 768
            //Labels
            label.fieldLabel {
                width: 200px;
                padding-top: 10px;
                float: left;
                clear: left;
            }
            //Field types
            select,
            input,
            textarea {
                width: 300px;
            }

            input[type=submit] {
                width: 174px;
                margin: auto;
            }

            .checkboxlist, radiobuttonlist, .checkbox {

                input[type="checkbox"],
                input[type="radio"],
                input {
                    display: inline-block !important;
                    margin-top: 1em;
                }
            }
        }
    }
}

/* Modules > Base | Deepend.com.au (c) 2014 | Author: Ray Ranola */

.mod {

	.inner {

		position: relative;

        &:after {
            content: ' ';
            display: block;
            clear: both;
            visibility: hidden;
            height: 0;
        }
	}

    .hd, .bd, .ft {
        overflow: hidden;
        _overflow: visible;
        zoom: 1;
    }
}
/**
*
* Page specific styles
*
**/
.product-page {

    //margin-top: 50px !important;

    ul {
        list-style-position: inside;
    }

    li {
        list-style-type: disc;
        margin-bottom: 20px;
    }

    .content {
        margin-top: 50px;
    }

}
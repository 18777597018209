/* Core > Margins | elray72.com (c) 2015 | tw: @elray72 */

/* Margins */
.push { margin: 10px; }
.push-t { margin-top: 10px; }
.push-b { margin-bottom: 10px; }
.push-l { margin-left: 10px; }
.push-r { margin-right: 10px; }

.push-0 { margin: 0; }
.push-5 { margin: 5px; }
.push-10 { margin: 10px; }
.push-15 { margin: 15px; }
.push-20 { margin: 20px; }
.push-40 { margin: 40px; }

.push-t0 { margin-top: 0; }
.push-t5 { margin-top: 5px; }
.push-t10 { margin-top: 10px; }
.push-t15 { margin-top: 15px; }
.push-t20 { margin-top: 20px; }
.push-t40 { margin-top: 40px; }

.push-b0 { margin-bottom: 0; }
.push-b5 { margin-bottom: 5px; }
.push-b10 { margin-bottom: 10px; }
.push-b15 { margin-bottom: 15px; }
.push-b20 { margin-bottom: 20px; }
.push-b40 { margin-bottom: 40px; }

.push-l0 { margin-left: 0; }
.push-l5 { margin-left: 5px; }
.push-l10 { margin-left: 10px; }
.push-l15 { margin-left: 15px; }
.push-l20 { margin-left: 20px; }
.push-l40 { margin-bottom: 40px; }

.push-r0 { margin-right: 0; }
.push-r5 { margin-right: 5px; }
.push-r10 { margin-right: 10px; }
.push-r15 { margin-right: 15px; }
.push-r20 { margin-right: 20px; }
.push-440 { margin-right: 40px; }

.push-tb5 { margin: 5px 0; }
.push-tb10 { margin: 10px 0; }
.push-tb15 { margin: 15px 0; }
.push-tb20 { margin: 20px 0; }
.push-tb40 { margin: 40px 0; }

.push-lr5 { margin: 0 5px; }
.push-lr10 { margin: 0 10px; }
.push-lr15 { margin: 0 15px; }
.push-lr20 { margin: 0 20px; }
.push-lr40 { margin: 0 40px; }
/**
*
* Product Finder
*
**/

$topOffset: 85px;

.product-finder {
    background-color: white;
    width: 100%;
    padding: 10px 20px;
    text-align: center;

    .input-row {
        max-width: 635px;
        margin: 0 auto;
    }


    h1 {
        @extend %Universal_45;
        margin: 10px 0px;
        font-size: 28px;
        color: $darkGrey;
        font-weight: bold;
        line-height: 34px;
    }


    p {
        font-size: 14px;
        display: inline-block;
    }


    select {
        @extend %Universal_65;
        display: block;
        height: 36px;
        width: 100% !important;
        margin: 0 0 10px 0;
        padding-left: 10px;
        float: none;
        font-size: 14px;
        line-height: 36px;
        color: $green;
        border: solid 1px #d7dbdd;

        &:disabled {
            color: #d7dbdd;
            cursor: default;
            opacity: 0.8;
        }
    }

    .btn-showme{
        @include btn_primary();

        &.disabled {
            opacity: 0.8;
            background-color: #d7dbdd;
            cursor: default;

            &:hover {
                text-decoration: none;
            }
        }
    }


    //Desktop
    @media #{$sm} {

        //MIN - 768
        padding: 30px;
        border: solid 1px #d7dbdd;

        .home-content & {
            position: relative;
            top: - $topOffset;
            z-index: 100;
            margin-bottom: - $topOffset;            
        }

        h1 {
            margin: 10px 0 20px 0;
        }

        p {
            font-size: 18px;
        }

        select {
            display: inline-block;
            margin: 0 15px 20px 15px;
        }

        //Input specific styles
        select#client-type {
            width: 330px !important;
        }

        select#turn-over {
            width: 155px !important;
        }

        select#loan-amount {
          width: 175px !important;
        }

        select#export-experience {
          width: 144px !important;
        }

        select#looking-for {
            margin: 0 0 15px;
            width: 100% !important;
        }


    }


    @media #{$md} {

        //MIN - 992
    }
}


#footer-signup {
    //height: 103px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    background-color: $green;

    .inner-wrap {
        height: 100%;
        width: 100%;
        max-width: 1600px;
        margin: auto;
    }

    .copy-block {
        padding: 20px 10px 0;
        width: 100%;

        p {
            margin: 0;
            color: $white;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .signup-block {
        padding: 27px 10px 0;
        width: 100%;

        .signup-text {
            margin-bottom: 10px;

            p {
                color: #fff;
                margin: 0;
                font-size: 16px;
                line-height: 18px;
            }
        }

        .checkbox {
            padding-right: 15px;

            label {
                width: 100%;
                display: inline-block;
                color: $white;
                font-size: 15px;
            }

            input {
                box-shadow: none;
                display: inline-block;
                margin-top: 0;
                margin-left: 0;
                padding-top: 3px;
                padding-left: 0;
                width: 16px;
                height: 16px;
                background: $white;
                border: 1px solid $white;
            }

            input::-moz-focus-inner {
                border: 0;
                padding: 0;
            }

            span {
                vertical-align: top;
            }
        }
    }

    .footer-email-input-block {
        height: 45px;
        margin-top: 27px;
        background-color: $white;

        #signup-email {
            margin: none;
            padding: none;
            width: 195px;
            height: 100%;
            color: #30383b;
            font-size: 18px;
            border: 1px solid $white;
        }

        #submit {
            display: none;
            float: right;
            margin: 0;
            padding: 0;
            width: 120px;
            height: 100%;
            padding-top: 20px;
            border: none !important;
            color: #fff;
            background-color: #30383b;
            text-align: center;
            cursor: pointer;
            padding-top: 12px;
            box-sizing: border-box;
        }

        //Mobile redirect
        .mobile-redirect {
            display: block;
            float: right;
            margin: 0;
            padding: 0;
            width: 80px;
            height: 100%;
            padding-top: 10px;
            border: none !important;
            color: #fff;
            background-color: #30383b;
            text-align: center;
            cursor: pointer;
        }
    }

    .social-links {
        padding: 10px;

        p {
           font-size: 16px;
           color: $white;
        }

        ul {
            list-style-type: none;
        }

        li {
            float: left;
            display: block;
            margin-right: 20px;
        }

        a {
            color: $white;
            font-size: 22px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    @media #{$sm} {
        .signup-column {
            float: left;
        }
        .copy-block {
            padding: 20px 0 0;
        }
        .signup-block {
            padding-left: 0;
            padding-right: 0;
        }
        .checkboxes {
            width: 60%;
            float: left;

            input {
                padding-right: 30px;
            }
        }
        .checkbox {
            width: 30%;
            float: left;
        }
        .footer-email-input-block {
            margin-top: 0;
            float: left;
            width: 40%;

            #signup-email {
                width: 200px;
            }
        }
        .mobile-redirect {
            display: none;
        }
        #submit {
            display: block;
        }
        .social-links {
            padding-left: 0;
            padding-right: 0;
            width: 100%;

            p {
                float: left;
            }

            ul {
                float: left;
                margin-left: 10px;
                margin-top: 10px;
            }
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}

//Force overlay submit button styles
.newslettersignup {
    input[type='submit']{

        display: block;
        background-color: #52ae32;
        width: 174px;
        z-index: 1000;
        padding: 0px;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        margin: 0 auto;
        border: none;
        outline: none;
        font-size: 16px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #b7bcbf;
        }
    }
}


//Moved from 'latest.css' as part of front end refactor
//BW 21/09/2015
.signup {
    top: -13px;
    color: white;

    .checkbox {
        float: left;
        padding-right: 15px;

         label {
            display: block !important;
            float: left;
            padding-right: 20px;
            white-space: nowrap;
            width: auto !important;

            span {
                vertical-align: middle;
            }
        }
         input {
            vertical-align: middle;
            width: 15% !important;
        }
    }
}

#footer {
    .footer-copy {
        /*max-width: 245px;*/
        padding: 0 0 0 33px;
        width: 20%;

        p {
            font-size: 24px;
            max-width: 245px;
        }
    }
    .footer-item {
        /*top: 26px;*/
        position: relative;
        padding-top: 25px;
    }

    .footer-social {
        padding-left: 55px;

         p {
            font-size: 16px;
        }
        .item {
            font-size: 26px;
            color: white;
            float: left;
            padding: 0 44px 0 8px;
        }
    }
}

//End refactor

/**
*
* Case Study Filter
*
**/

$topOffset: 85px;

.case-study-filter {
    background-color: white;
    width: 100%;
    padding: 10px 10px;
    text-align: center;

    .input-row {
        max-width: 100%;
        margin: 0 auto;
    }

    .reset{
      a{
          text-decoration: underline;
      }
      margin-top:10px;
    }


    h1 {
        @extend %Universal_45;
        margin: 10px 0px 10px 0px;
        font-size: 28px;
        color: $darkGrey;
        font-weight: bold;
        line-height: 34px;
    }


    p {
        font-size: 14px;
        display: inline-block;
    }


    select {
        @extend %Universal_65;
        display: block;
        height: 36px;
        width: 100% !important;
        margin: 0 0 10px 0;
        padding-left: 10px;
        float: none;
        font-size: 14px;
        line-height: 36px;
        color: $green;
        border: solid 1px #d7dbdd;

        &:disabled {
            color: #d7dbdd;
            cursor: default;
            opacity: 0.8;
        }
    }

    .btn-filter{

        @include btn_primary();
        width: 332px;
        &.disabled {
            opacity: 0.8;
            background-color: #d7dbdd;
            cursor: default;

            &:hover {
                text-decoration: none;
            }
        }
    }


    //Desktop
    @media #{$sm} {

        //MIN - 768
        padding: 30px;
        border: solid 1px #d7dbdd;


        h1 {
            margin: 10px 0px;
        }

        p {
            font-size: 18px;
            margin-bottom: 20px;
        }

        select {
            display: inline-block;
            margin: 0 15px 20px 15px;
            width: 230px !important;
        }



    }


    @media #{$md} {

        //MIN - 992
    }
}

/* EFIC > Core > Fonts | Deepend.com.au (c) 2015 | Author: Ray Ranola */

// Universal Regular
@font-face {
    font-family: 'UniversLTStd55Roman';
            src: url('/static/fonts/universal/universltstd.eot');
            src: local('☺'),
                 url('/static/fonts/universal/universltstd.woff') format('woff'),                             // Modern Browsers
                 url('/static/fonts/universal/universltstd.ttf') format('truetype'),                          // Safari, Android, iOS
                 url('/static/fonts/universal/universltstd.svg#UniversLTStd55Roman') format('svg');           // IE9 Compatibility Modes
}

// Universal Light
@font-face {
    font-family: 'UniversLTStd45Light';
            src: url('/static/fonts/universal/universltstd-light.eot');
            src: local('☺'),
                 url('/static/fonts/universal/universltstd-light.woff') format('woff'),                       // Modern Browsers
                 url('/static/fonts/universal/universltstd-light.ttf') format('truetype'),                    // Safari, Android, iOS
                 url('/static/fonts/universal/universltstd-light.svg#UniversLTStd45Light') format('svg');     // IE9 Compatibility Modes
}

// Universal Bold
@font-face {
    font-family: 'UniversLTStd65Bold';
            src: url('/static/fonts/universal/universltstd-bold.eot');
            src: local('☺'),
                 url('/static/fonts/universal/universltstd-bold.woff') format('woff'),                        // Modern Browsers
                 url('/static/fonts/universal/universltstd-bold.ttf') format('truetype'),                     // Safari, Android, iOS
                 url('/static/fonts/universal/universltstd-bold.svg#UniversLTStd65Bold') format('svg');       // IE9 Compatibility Modes
}


//come back to after font sorted out
// Decima Regular
@font-face {
    font-family: 'TipografiaRamis-Decima';
            src: url('/static/fonts/decima/TipografiaRamis-Decima.otf');
            src: local('☺'),
                 url('/static/fonts/decima/TipografiaRamis-Decima.ttf') format('ttf');

}

@font-face {
    font-family: 'TipografiaRamis-DecimaLight';
            src: url('/static/fonts/decima/TipografiaRamis-DecimaLight.otf');
            src: local('☺'),
                 url('/static/fonts/decima/TipografiaRamis-Decima.woff'),
                 url('/static/fonts/decima/TipografiaRamis-DecimaLight.ttf') format('ttf');
}

/* Variable Declarations */
$universalReg: 'UniversLTStd55Roman', 'Open Sans', 'Droid Sans', Arial, sans-serif;
$universalLight: 'UniversLTStd45Light', 'Open Sans', 'Droid Sans', Arial, sans-serif;
$universalBold: 'UniversLTStd65Bold', 'Open Sans', 'Droid Sans', Arial, sans-serif;
$decimaReg: 'Decima', 'Open Sans', 'Droid Sans', Arial, sans-serif;
$decimaLight: 'TipografiaRamis-DecimaLight', 'Open Sans', 'Droid Sans', Arial, sans-serif;

// Class Declarations
.universalReg {
    font-family: $universalReg;
}

.universalLight {
    font-family: $universalLight;
}

.universalBold {
    font-family: $universalBold;
}

.decimaReg {
  font-family: $decimaReg;
}

.decimaLight {
  font-family: $decimaLight;
}

/*Silent extends*/
%Universal_45 { font-family: 'UniversLTStd45Light', 'Open Sans', 'Droid Sans', Arial, sans-serif; }
%Universal_55 { font-family: 'UniversLTStd55Roman', 'Open Sans', 'Droid Sans', Arial, sans-serif; }
%Universal_65 { font-family: 'UniversLTStd65Bold', 'Open Sans', 'Droid Sans', Arial, sans-serif; }

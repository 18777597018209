/**
*
* Home slider a-feature, homepage only
*
**/
.home-slider {
	width: 100%;
	position: relative;

	//Swiper styles
	.swiper-wrapper,
	.swiper-slide {
		//Required to fix swiper JS height fixing
		height: auto !important;
	}

	.swiper-pagination {
		width: 100%;
	}

	.img-block {
		width: 100%;
		//min-height: 232px;

		img {
			width: 100%;
			display: block;
		}
	}

	.slide-content {
		@extend %gradient_45;
		position: absolute;
		left: 0;
		bottom: 0;
		color: white;
		width: 100%;
		height: 178px;
		padding: 27px;
		background-color: green;

		* {
		  color: white;
		}
	}

	.title {
		@extend %Universal_55;
		font-size: 30px;
		line-height: 38px;
		margin-top: 0;
		color: white;
	}

	.copy {
		@extend %Universal_45;
		display: none;
	}

	.btn-cta {
		@include btn_primary();
	}

	.slider-link {
	  position: absolute;
	  display: block;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	}

	.alternate-slide {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position:center center;
		background-size:cover;
		position:absolute;

		.container {
			position: relative;
			margin-top: 30px;
			padding-left: 0;
			padding-right: 0;
		}

		.heading {
			display:block;
			margin: 0 auto;
			width: 70%;
			text-align:center;

			h2 {
				margin: 0;
				color: white;
				font-size: 39px !important;
				line-height: 45px;
				font-family: $decimaLight;
			}
		}
	}

	.circles {
		display:block;
		margin: 40px auto 0;
		width: 100%;
		text-align:center;
		visibility: hidden;
	}

	.circle {
		display:inline-block;
		margin-left: 40px;
		margin-right: 40px;
		width: 160px;
		height: 160px;
		border-radius: 50%;
		vertical-align: top;
		background: rgba(#2d3a41, 0.6);

		h3 {
			margin-top: 26px;
			margin-bottom: 5px;
			color: white;
			font-size: 45px;
			font-weight: bold;
			text-align: center;
			font-family: $universalBold;
		}

		p {
			display: block;
			margin: 0 auto;
			width: 70%;
			color: white;
			text-align: center;
			font-family: $universalLight;
		}
	}

	//Desktop
	@media #{$sm} {
		.swiper-wrapper,
		.swiper-slide {
			max-height: 350px;
		}
		.swiper-pagination {
			bottom: 2.5%;
		}

		.slide {
			position: relative;
		}

		.content-block {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.inner {
			position: relative;
			width: 100%;
			height: 100%;
		}

		.slide-content {
			position: absolute;
			top: 20%;
			left: $grid-gutter-width;
			width: 230px;
			height: 207px;
		}

		.alternate-slide {
			.container {
				top: 35%;
				margin-top: 0;
				transform: translateY(-50%);
			}
		}

		.circles {
			margin-top:5px;
			visibility: visible;
		}

		.heading {
			width:80%;
		}
	}

	@media #{$md} {
		.slide-content {
			width: 320px;
			height: 254px;
		}

		.copy {
			display: block;
			font-size: 16px;
			color: white;
		}

		.btn-cta {
			margin-top: 10px;
		}

		.circles {
			margin-top:20px;
		}

		.heading {
			width:60%;
		}
	}
}

/**
*
* Large feature tiles, used on homepage primarily below full width
* feature row.
* Image on left, copy + CTA on right
*
**/
.feature-tile {
    background-color: white;
    border: solid 1px #d7dbdd;
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    .content-block {
        padding: 20px;
        margin-bottom: 30px;
    }

    .title {
        @extend %Universal_45;
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
        font-size: 21px;
        font-weight: bold;
        color: #2f383a;
        line-height: 1.2;
    }

    p {
        @extend %Universal_45;
        font-size: 16px;
        line-height: 24px;
        color: #2f383a;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
    }

    .btn-cta {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
    //Desktop
    @media #{$sm} {
        .content-block {
            padding: 25px 20px;
        }
    }
}

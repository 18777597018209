/**
*
* Push Down Notification
* Only apply to SBEL pages at moment
*
**/

.pushdown-notification {
    @extend %Universal_55;
    position: relative;
    display: block;
    padding: 0;
    width: 100%;
    font-size: 16px;
    text-align: center;
    overflow: hidden;
    background-color: $grey;

    &.ready {
        top: auto;
    }

    .container-fluid {
        @include clearfix;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .close {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        color: $text-color;
        font-weight: bold;
        border: 0 none;
        background: transparent;

        &:hover {
            color: $white;
            background: $hoverGrey;
        }
    }

    .notification-content {
        padding: 0 20px;

        .btn-cta {
            display: block;
            margin-top: 15px;
            padding-left: 20px;
            padding-right: 20px;
            border: 0 none;
        }
    }

    @media #{$sm} {
        //MIN - 768

        .close {
            position: relative;
            display: block;
            top: auto;
            right: auto;
            float: right;
        }

        .notification-content {
            padding: 0;

            .btn-cta {
                display: inline-block;
                margin-top: 0;
                margin-left: 15px;
            }
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}

/*
	jQuery.mmenu position extension CSS
*/

@import "_inc/variables";


.mm-menu.mm-top,
.mm-menu.mm-bottom
{
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

.mm-menu.mm-right
{
	left: auto;
	right: 0;
}

.mm-menu.mm-bottom
{
	top: auto;
	bottom: 0;
}

@include mm_sizing_right;



/*
	jQuery.mmenu z-position extension CSS
*/

// reset defaults
html.mm-front
{
	.mm-slideout
	{
		@include mm-webkit-prefix( 'transform', none !important );
		z-index: 0 !important;
	}
}

// styling
.mm-menu.mm-front
{
	z-index: 1;
}

// animations
.mm-menu
{
	&.mm-front,
	&.mm-next
	{
		-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
		-ms-transition: -ms-transform $mm_transitionDuration $mm_transitionFunction;
		transition: transform $mm_transitionDuration $mm_transitionFunction;

		@include mm-webkit-prefix( 'transform', translate3d( -100%, 0, 0 ) );

		&.mm-right
		{
			@include mm-webkit-prefix( 'transform', translate3d( 100%, 0, 0 ) );
		}
	}
	&.mm-top
	{
		@include mm-webkit-prefix( 'transform', translate3d( 0, -100%, 0 ) );
	}
	&.mm-bottom
	{
		@include mm-webkit-prefix( 'transform', translate3d( 0, 100%, 0 ) );
	}
}
html.mm-opening .mm-menu
{
	&.mm-front,
	&.mm-next
	{
		@include mm-webkit-prefix( 'transform', translate3d( 0, 0, 0 ) );
	}
}

@include mm_sizing_zposition;
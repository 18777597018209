

label {
	font-family: 'UniversLTStd55Roman';
}

.contour input.text {
	height: 30px;
	font-size: 15px;
	font-family: 'UniversLTStd45Light';
	float: left;
}

#contour select, .contour select{
    width: 301px;
}

select {
	height: 40px;
	font-size: 15px;
	font-family: 'UniversLTStd45Light';
	width: 312px;
	float: left;
}

#contour_form_Contactus .contourField {
	width: 350px;
	display: inline-block;
	margin-bottom: 30px;
	height: 70px;
}

.contourPageName {
	margin-left: 2px;
}

#div_553160fe-6ada-4174-9294-64aca38f1ed6 {
	/*display: none;*/
	height: 300px !important;
	margin-bottom: 0 !important;
}

#contour_form_Contactus > form > div.contourPage > fieldset {
	margin: 0 !important;
}

.contourNavigation {
	width: 86%;
}

.contourNavigation .loading-image {
    display: block;
    margin: auto;
    padding: 10px;
}

.contourNavigation .submitting-message {
    text-align: center;
}

.contourPageName {
	margin-left: 20px;
}

.home-wide-button {
	background-color: #52ae32;
	width: 174px;
	z-index: 1000;
	padding: 0px;
	margin: 0 auto;
	/*height: 38px;*/
	float: right;
}


.home-wide-button p {
	font-size: 16px;
}

.home-wide-button a:hover {
	text-decoration: none;
}

.home-wide-button:hover {
	background-color: #b7bcbf;
}

@media (max-width: 720px) {
	.home-wide-button {
		float: none;
	}

	.home-wide-button p {
		line-height: 40px;
	}
	.field-validation-error{
		margin-left: 0px;
	}

	.complaints .home-wide-button p, .categoryaprojects .home-wide-button p, .prequaltool .home-wide-button p, .publicinterestdisclosure .home-wide-button p {
		line-height: 18px !important;
	}
}

@media (max-width: 590px) {
	#complaintSubmitSpacer {
		display: none;
	}
	.complaints .field-validation-error, .categoryaprojects .field-validation-error, .prequaltool .field-validation-error, .publicinterestdisclosure .field-validation-error {
		left: 0 !important;
	}
}

@media (min-width: 720px) {
	.field-validation-error{
		/*margin-left: 210px;*/
	}
}

.field-validation-error{
	clear: both;
	float: left;
}

//Global form submit button
input[type='submit']{
	display: block;
    background-color: #52ae32;
    margin: 0 auto;
    width: 174px;
    padding: 10px 0px !important;
    border: none;
    color: white;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
    	background-color: #b7bcbf;
    }
}


//Hard form fixes
@media (max-width: 768px) {
	.contourNavigation {
		width: 100%;
	}

	#contour_form_Contactus .contourField {
		width: 100%;
		//display: inline-block;
		//margin-bottom: 30px;
		//height: 70px;
	}

	#contour input.text, #contour textarea,
	.contour input.text, .contour textarea	{
		width: 100% !important;
		padding: 0  !important;
	}
}

.contourPage {
    a {
        color: $green;
        text-decoration: underline;

        &:hover {
            color: #b7bcbf;
        }
    }
}
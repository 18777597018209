/**
*
* Global swiper controls
*
**/

//Pagination
.swiper-pagination {

	//Default
	.swiper-pagination-switch {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-left: 3px;
		margin-right: 3px;
		background-color: rgba(40,51,56,0.2);
		border: solid 1px rgba(255,255,255,0.5);

		//Active state
		&.swiper-active-switch {
			background-color: $lightGreen;
			border: solid 1px $lightGreen;
		}
	}
}

//Next and previous
.swiper-btn {
	display: none;

    //Desktop
    @media #{$sm} {
    	display: block;
		position: absolute;
		z-index: 100;
		top: 50%;
		display: block;
		width: 40px;
		height: 60px;
		margin-top: -30px;
		background-color: rgba(255,255,255,0.2);
		cursor: pointer;

		&.prev { left: 0px;}
		&.next { right: 0px;}

		&:hover {
			background-color: $green;
		}

		.icon {
			color: white;
			font-size: 36px;
			position: relative;
			top: 14px;
			left: 2px;
		}
	}
}

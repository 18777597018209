/**
*
* Core structural classes and elements
*
**/
#master-content {
    width: 100%;
    margin: auto;
}

.container-fluid {
    max-width: 960px;
    margin: auto;
}

.container-fullwidth {
    width: 100%;
    max-width: 1600px;
    margin: auto;

    &.home-container {
        max-width: 1400px;
    }
}

.link-row {
    margin-bottom: 30px;

    .cta-link {
        margin-top: 0;
    }
}

.no-padding {
    padding: 0;
}

.row-condensed {
    margin-left: 0;
    margin-right: 0;

    > div[class^="col-"] {
        padding-left: 0px;
        padding-right: 0px;
    }
}

[hidden] {
    display: none !important;
}

/**
*
* Core button styles
*
**/

//Depending on how many button variants we end up with,
//this may move to mixins file
@mixin btn_primary() {
    @extend %Universal_45;
    display: inline-block;
    padding: 6px 10px;
    background-color: $green;
    border: solid 1px white;
    color: white;
    font-size: 17px;
    cursor: pointer;

    &:hover {
        color: $white;
        background-color: $hoverGrey;
    }
}


//Button classes
.btn-cta, .cta-link, .cta-link-alt {
    @include btn_primary()
}

.cta-link, .cta-link-alt {
    display: inline-block;
    margin: 20px 0;
    padding: 0 26px;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
}

.cta-link-alt {
    background-color: $altGrey;
}
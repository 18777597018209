/********************************
Micro Clearfix
Source:
https://gist.github.com/mike-henderson/4948107
http://nicolasgallagher.com/micro-clearfix-hack/

Usage:
elem {
	@include clearfix-micro();
}
********************************/

@mixin clearfix-micro() {
    & {
        *zoom: 1;
    }
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin transform($property) {    
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
}
/**
*
* Form style / structure overrides for Efic Direct Checklist form ONLY
*
**/

#contour_form_EficDirectChecklist {
	label {
		width: auto;
		float: none;
		clear: none;
	}

	.contourField  {
		position: relative;
		clear: both;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 20px;

	}

	.tooltip {
		position: absolute;
		right: 0;
		top: 20px;
		cursor: pointer;
	}

	span.radiobuttonlist {
		float: none;
		padding: 15px 0;
	}

    //Desktop
    @media #{$sm} {
    	.contourField {
	    	padding-right: 0;
	    }

	    .fieldLabel {
	    	display: inline-block;
	    }


	    .tooltip {
	    	display: inline-block;
	    	position: relative;
	    	right: initial;
	    	top: 3px;
	    	margin-left: 10px;

	    }
    }
}

/**
*
* Product page - Quick links
*
**/
.product-quick-links {
    padding-top: 30px;
    padding-bottom: 20px;

    p {
        @extend %Universal_65;
        margin-bottom: 25px;
        font-size: 22px;
        color: #000;
    }


    ul {
        list-style-position: inside;
    }

    li {
        list-style-type: disc;
        margin-bottom: 20px;
    }

    a {
        @extend %Universal_65;
        text-decoration: underline;
    }
}

/**
*
* Global, reusable animations
*
**/
$animationTime: 250ms;

@mixin animate-opacity() {
    -webkit-transition: opacity  $animationTime linear;
    -moz-transition: opacity  $animationTime linear;
    -ms-transition: opacity  $animationTime linear;
    -o-transition: opacity  $animationTime linear;
    transition: opacity  $animationTime linear;
}

@mixin animate-background() {
    -webkit-transition: background $animationTime linear;
    -moz-transition: background $animationTime linear;
    -ms-transition: background $animationTime linear;
    -o-transition: background $animationTime linear;
    transition: background $animationTime linear;
}

@mixin animate-color() {
    -webkit-transition: color $animationTime linear;
    -moz-transition: color $animationTime linear;
    -ms-transition: color $animationTime linear;
    -o-transition: color $animationTime linear;
    transition: color $animationTime linear;
}

@mixin animate-none() {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

@mixin animate-custom($propertyOne, $propertyTwo){

    -webkit-transition: $propertyOne $animationTime, $propertyTwo $animationTime linear;
    -moz-transition: $propertyOne $animationTime, $propertyTwo $animationTime linear;
    -ms-transition: $propertyOne $animationTime, $propertyTwo $animationTime linear;
    -o-transition: $propertyOne $animationTime, $propertyTwo $animationTime linear;
    transition: $propertyOne $animationTime, $propertyTwo $animationTime linear;

    /*
	-webkit-transition: color $animationTime, background $animationTime linear;
    -moz-transition: color $animationTime, background $animationTime linear;
    -ms-transition: color $animationTime, background $animationTime linear;
    -o-transition: color $animationTime, background $animationTime linear;
    transition: color $animationTime, background $animationTime linear;
    */
}
﻿.resource-finder {
    $moduleSpacingVertical: 40px;
    margin-bottom: $moduleSpacingVertical;

    .copy {
        margin: ($moduleSpacingVertical + 10px) 0 $moduleSpacingVertical 0;

        @media (max-width: $screen-xs-max) {
            p {
                display: none;
            }
        }
    }

    .mobile-header {
        display: none;
        margin: 0 (-($grid-gutter-width / 2));
        background-color: #5eb33a;
        align-items: center;

        .btn-filter {
            height: 70px;
            width: 70px;
            background-color: #4a9c2d;
            color: #FFFFFF;
            border: none;
            font-size: 20px;
        }

        .title {
            margin: 0;
            color: #FFFFFF;
            font-size: 16px;
            flex: 1;
            text-align: center;
        }
    }

    &.has-filters {
        @media (max-width: $screen-xs-max) {
            .copy,
            .filters {
                display: none;
            }

            .mobile-header {
                display: flex;
            }
        }
    }

    .filters {
        border-top: 1px solid #cecece;
        padding: 10px 25px;
        margin: $moduleSpacingVertical (-($grid-gutter-width / 2));

        @media #{$sm} {
            border: 1px solid #cecece;
            margin: $moduleSpacingVertical 0;
        }

        .site-overlay & {
            border: none;
            margin: 0;
        }

        $filterCtrlHeight: 42px;

        .filter-group {
            margin: 20px 0;

            > label {
                display: inline-block;
                margin-bottom: 10px;
            }
        }

        select {
            @extend %Universal_65;
            width: 100%;
            height: $filterCtrlHeight;
            display: block;
            padding-left: 10px;
            font-size: 15px;
            line-height: $filterCtrlHeight;
            border-radius: 3px;
            border-color: #CECECE;
            color: $green;
            background-color: #F8F8F8;
        }

        .dropdown-toggle {
            @extend %Universal_65;
            width: 100%;
            height: $filterCtrlHeight;
            position: relative;
            display: block;
            padding-left: 10px;
            font-size: 15px;
            line-height: $filterCtrlHeight;
            border-radius: 3px;
            border: 1px solid #CECECE;
            text-align: left;
            color: $green;
            background-color: #F8F8F8;
            padding-right: 20px;
            text-overflow: ellipsis;
            overflow: hidden;

            &:after {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 4px dashed;
                border-top: 4px solid \9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                position: absolute;
                right: 8px;
                top: $filterCtrlHeight / 2;
            }
        }

        .dropdown-menu {
            background-color: #F8F8F8;
            top: -2px;
            right: 0;
            max-height: 350px;
            overflow: auto;

            label {
                display: flex;
                padding: 10px;
                cursor: pointer;

                &:hover,
                &:focus {
                    background-color: #D7DBDD;
                }

                span {
                    flex: 1;
                    word-break: break-word;
                }
            }

            input[type="checkbox"] {
                position: relative;
                top: 2px;
                display: inline-block;
                width: 16px;
                height: 16px;
                background: #fff;
                border: solid 1px #FFF;
                box-shadow: none;
                margin-right: 10px;
            }
        }

        .btn-container {
            text-align: center;

            @media #{$sm} {
                padding-top: 30px;
            }
        }

        .btn-find {
            @include btn_primary();
            height: $filterCtrlHeight;
            margin-right: 15px;

            @media (max-width: $screen-xs-max) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        .btn-reset {
            text-decoration: underline;
            padding: 0;
            background: none;
            border: none;
        }
    }

    .selected-filters {
        margin: $moduleSpacingVertical 12px;
        position: relative;

        @media #{$sm} {
            margin: $moduleSpacingVertical 0;
            text-align: center;
        }

        .title {
            @extend %Universal_45;
            margin: 10px 0px;
            font-size: 28px;
            color: $darkGrey;
            font-weight: bold;
            line-height: 34px;
        }

        .mod-social-share {
            margin-bottom: 0;

            @media #{$md} {
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 0;
            }
        }

        .tags {
            margin: 25px 0;

            li {
                display: inline-block;
            }
        }

        .tag {
            position: relative;
            margin: 10px;
            padding: 12px 40px 12px 16px;
            border: 1px solid #CECECE;
            border-radius: 3px;
            cursor: pointer;
            background-color: #FFFFFF;

            &:after {
                content: '\61';
                font-family: 'efic';
                position: absolute;
                right: 16px;
                padding-top: 1px;
            }

            &:hover,
            &:focus {
                border-color: $green;
            }

            &:hover:after,
            &:focus:after {
                color: $green;
            }
        }
    }

    .resource-list {
        margin: $moduleSpacingVertical (-($grid-gutter-width / 2));

        @media #{$sm} {
            margin: $moduleSpacingVertical 0 20px;
        }

        .resource-item {
            border: 1px solid #cecece;
            border-bottom: none;

            &:hover {
                background-color: #F7F7F7;
            }

            &:last-child {
                border-bottom: 1px solid #cecece;
            }

            .image-container {
                position: relative;
            }

            .image {
                width: 100%;
                height: auto;
            }

            .type {
                @extend %Universal_65;
                position: absolute;
                top: 0;
                width: 100%;
                padding: 15px 0;
                font-size: 15px;
                line-height: 1;
                text-align: center;
                background-color: #5eb33a;
                color: #FFF;

                .icon {
                    position: absolute;
                    left: 15px;
                    top: 10px;
                    font-size: 20px;
                    display: none;
                }
            }

            @media #{$sm} {
                .type {
                    text-align: left;
                    padding: 15px 15px 15px 45px;

                    .icon {
                        display: block;
                    }
                }
            }

            .content-container {
                padding: 15px;

                @media #{$sm} {
                    padding: 15px 30px;
                }
            }

            .name {
                @extend %Universal_65;
                color: $green;
                margin: 0;
                font-size: 16px;
                line-height: 1.4;
                max-height: 1.4 * 16px * 2;
                overflow: hidden;
            }

            p {
                max-height: 2 * 20px;
                overflow: hidden;

                @media #{$sm} {
                    max-height: 3 * 20px;
                }

                @media #{$md} {
                    max-height: 5 * 20px;
                }
            }
        }
    }
}

.pager {
    float: right;
    border: 1px solid #cecece;

    .count {
        padding: 0 25px;
        display: block;
        float: left;
        height: 40px;
        line-height: 40px;
    }

    .prev,
    .next {
        display: block;
        float: left;
        height: 40px;
        width: 40px;
        position: relative;

        &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: middle;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            position: absolute;
            left: 15px;
            top: 15px;
        }
    }

    .prev:before {
        border-right: 6px dashed;
    }

    .next:before {
        border-left: 6px dashed;
    }
}

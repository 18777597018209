/* EFIC > Efic Mincer | Deepend.com.au (c) 2015 | Author: Ray Ranola */


@import "config/variables";
@import "config/branding";

//Bootstrap FIRST
@import "vendor/bootstrap";

//Legacy CSS
@import "legacy/legacy";
@import "legacy/form";

//Mixins
@import "mixins/general";

// UI Elements
@import "ui-elements/media";
@import "ui-elements/buttons";
@import "ui-elements/gradients";
@import "ui-elements/swiper-controls";
@import "ui-elements/animations";
@import "ui-elements/tooltips";
@import "ui-elements/contour-forms";
@import "ui-elements/divider-line";

// Core
@import "core/fonts";
@import "core/icons";
@import "core/module";
@import "core/borders";
@import "core/margins";
@import "core/structure";
@import "core/page-types";

// Vendor and 3rd party
@import "vendor/fresco";
@import "vendor/magnific";
@import "vendor/swiper";
@import "vendor/jquery.mmenu";

// Modules
/*
REMOVING AS PART OF HOMEPAGE REBUILD
BW 24/12/2016

@import "modules/header";

*/

@import "modules/components";
@import "modules/tiles";
@import "modules/social";
@import "modules/iframes";
@import "modules/table";
@import "modules/modal";
@import "modules/accordion";
@import "modules/events-list";
@import "modules/social-twitter";
@import "modules/footer";
@import "modules/footer-signup";
@import "modules/tab-nav";
@import "modules/richtext-content";
@import "modules/download-form";
@import "modules/promo-tiles";
@import "modules/table-listing";
@import "modules/side-promo-tile";
@import "modules/fullwidth-banner";
@import "modules/efic-direct-form";
@import "modules/loan-calculator";
@import "modules/home-slider";
@import "modules/product-hero";
@import "modules/product-apply-steps";
@import "modules/product-eligibility-criteria";
@import "modules/product-finder";
@import "modules/product-quick-links";
@import "modules/feature-tile";
@import "modules/feature-video-tile";
@import "modules/latest-news";
@import "modules/master-header";
@import "modules/utility-bar";
@import "modules/primary-nav";
@import "modules/mobile-nav";
@import "modules/tertiary-nav";
@import "modules/recommended-product-tile";
@import "modules/breadcrumbs";
@import "modules/alt";
@import "modules/faq";
@import "modules/resource-finder";
@import "modules/popup-notification";
@import "modules/pushdown-notification";
@import "modules/site-overlay";
@import "modules/table-styles";
@import "modules/case-study-filter";

// Hacks
@import "core/_ridiculous-hacks";



//Temporary modal hotfix
.newsletter-signup-partner.simplemodal-data,
#simplemodal-overlay,
#simplemodal-container {
	box-sizing: content-box;
}

/**
*
* Latest news column, right of feature tiles on
* on homepage
*
**/

/*
Total news column needs fixed height to match height of
tiles to the left. Height of tiles is actually set
in the '_feature-tile.scss' file, below is a simple
equation using that height

NOTE: This is ONLY applicable for desktop / large devices
*/

$tileHeight: 300px;
$tileMargin: 20px;
$num_Tiles: 3;

$totalColumnHeight: ($tileHeight + $tileMargin) * $num_Tiles - $tileMargin;

//News section heights
$newsHeader: 75px;  //Title block, with text
$newsFooter: 92px;  //Footer block, with CTA
$newsStories: 2;    //Number of news items

/*
Based on above calculate the height of each news item
Fixed height is required to obscure text as per design
*/
$newsStoryHeight: ($totalColumnHeight - ($newsHeader + $newsFooter)) / 2;
//
// .latest-news {
//
//     background-color: white;
//     border: solid 1px #d7dbdd;
//
//     //Structure
//     .header {
//         height: 70px;
//         padding: 20px;
//         border-bottom: solid 1px #d7dbdd;
//     }
//
//     .news-story {
//         //height: 160px;
//         position: relative;
//         padding: 20px;
//         border-bottom: solid 1px #d7dbdd;
//         overflow: hidden;
//     }
//
//     .footer {
//         height: 80px;
//         padding: 25px 20px 0 20px;
//     }
//
//
//     //Text and content styles
//     .title {
//         @extend %Universal_45;
//         margin-top: 5px;
//         font-size: 20px;
//         font-weight: bold;
//         color: #2f383a;
//     }
//
//     .story-title {
//         @extend %Universal_65;
//         margin-top: 0;
//         font-size: 16px;
//         line-height: 24px;
//         color: #2f383a;
//     }
//
//     .story-content {
//         @extend %Universal_45;
//         display: none;
//     }
//
//     .story-link {
//         @extend %Universal_65;
//         margin-top: 0;
//         font-size: 16px;
//         line-height: 24px;
//         color: #2f383a;
//         text-decoration: underline;
//     }
//
//
//     //Desktop
//     @media #{$sm} {
//         //MIN - 768
//         .news-story {
//             height: 160px;
//
//             //Obscuring element
//             &:after {
//                 content: '';
//                 display: block;
//                 width: 100%;
//                 height: 120px;
//                 position: absolute;
//                 left: 0;
//                 bottom: 0;
//                 z-index: 5;
//
//                 background: rgba(255,255,255,0);
//                 background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 8%, rgba(255,255,255,1) 54%, rgba(255,255,255,1) 100%);
//                 background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(8%, rgba(255,255,255,0)), color-stop(54%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
//                 background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 8%, rgba(255,255,255,1) 54%, rgba(255,255,255,1) 100%);
//                 background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 8%, rgba(255,255,255,1) 54%, rgba(255,255,255,1) 100%);
//                 background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 8%, rgba(255,255,255,1) 54%, rgba(255,255,255,1) 100%);
//                 background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 8%, rgba(255,255,255,1) 54%, rgba(255,255,255,1) 100%);
//                 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
//             }
//         }
//
//         .story-content {
//             display: block;
//             margin-top: 0;
//             font-size: 16px;
//             line-height: 24px;
//             color: #2f383a;
//         }
//
//         .story-link {
//             position: absolute;
//             left: 20px;
//             bottom: 20px;
//             z-index: 10;
//         }
//     }
//
//     @media #{$md} {
//         max-height: $totalColumnHeight;
//
//         .header {
//             height: $newsHeader;
//         }
//
//         .footer {
//             height: 82px; //$newsFooter;
//         }
//
//         .news-story {
//             height: $newsStoryHeight;
//         }
//     }
//
// }

.side-panel {
  p {
    padding: 0;
    margin: 0;
  }
  .col-md-9 {
    padding:0px;
  }

  .panel-icon-link {
    position:relative;
    top:20px;
    margin-right:10px;
    font-size:30px;
    color: #52ae32;
    float:right;
  }

  .panel-icon-info {
    position:relative;
    top:20px;
    margin-right:9px;
    font-size:30px;
    color: #52ae32;
    float:right;

  }

  .download-links {
    .header {
      height: 70px;
      background-color: #d7dbde;
      padding-left:10px;

      a {
        text-decoration: none;
        color: #2d3a41;
        font-size:19px;
        font-weight:bold;
        padding:0px;
        margin:0px;
        position:relative;
        top:15px;
        vertical-align: middle;
      }




    }

    .footer {
      padding-top:21px;
      padding-left:21px;
      border: 1px solid #d7dbdd;
      a {
        margin-top: 15px;
        margin-bottom: 15px;
        text-decoration:underline;
        line-height:32px;
      }
    }
  }

  .contact {
    .header {
      background-color:#5EB33A;
      padding:23px;
      padding-top:23px;
      height:200px;

      p {
        margin: 0;
        padding: 0;
        color: white;
        height: 20px;
        font-size: 17px;
        overflow: hidden;
      }

      .title {
        padding-bottom:53px;
        letter-spacing:1px;
        font-size:20px;
      }

      .contact-second-title {
        margin-bottom:10px;
      }

      .phone-number {
        padding-bottom:45px;
          letter-spacing:1px;
      }
      .email-link {
        letter-spacing: 1px;
      }

      a {
        color:white;
        text-decoration: underline;
      }
    }

    .footer {
    background-color:white;
    border: 1px solid #d7dbdd;
    padding:23px;

      p {
        font-size:17px;
      }
    }

  }

  .faq-section {
    .header {
      height: 70px;
      background-color: #d7dbde;
      padding-left:21px;

      p, a {
        font-weight:bold;
        font-size:19px;
        color: #2d3a41;
        line-height: 70px;
      }

      a:hover {
        color: $link-color;
        text-decoration: underline;
      }
    }
    .footer {

      padding:20px 20px 9px 20px;
      border: 1px solid #d7dbdd;

      .popover {
        position:absolute;
        right:500px;
        padding:5px 0px 10px 0px;
        margin:10px;
        background-color:white;
        z-index:100;
        border-radius:5px;
        width:300px !important;
      }

      p {
        margin-bottom: 10px;
      }

      a:not(.btn-cta) {
        text-decoration:underline;
        cursor:pointer;
      }

      > ul > li {
        margin-bottom:20px;
      }
    }
  }

  .footer {
    background-color:white;
    border: 1px solid #d7dbdd;
    padding:22px;

    img {
      display: block;
      margin: 0 auto;
      height:auto;
      width: auto;
      padding-bottom:18px;
    }

    p {
      font-size:15px;
      line-height:22px;
    }
  }

    //custom queries for download & quick links text
  @media (min-width:368px)  {
    .download-links .header a {
      position:relative;
      top:25px;
    }
  }

  @media (min-width: 990px) {
    .download-links .header a {
      position:relative;
      top:14px;
    }
  }
}

/**
*
* Loan Calculator
*
**/
.loan-calculator {

    .calculator-border {
        margin: 50px auto;
        padding: 20px;
        background-color: white;
        border: solid 1px #cecece;
    }


    //Common text styles
    p.label {
        @extend %Universal_65;
        font-size: 16px;
        color: black;
    }

    input,
    select {
        @extend %Universal_45;
        font-size: 16px;
        border: 1px solid #d7dbdd;
        background-color: white;
        width: 100% !important;
        padding: 10px !important;
        float: none !important;
    }

    input.accrue-rate {
        display: none;
        visibility: hidden;
    }

    .title-row {
        text-align: center;
        p {
            @extend %Universal_45;
            font-size: 28px;
            color: #30383b;
            font-weight: bold;
            line-height: 29px;
        }
    }

    .loan-conditions-block {
        padding: 10px;
    }

    .field-row {
        padding-bottom: 20px;
    }

    .interest-row {
        padding-bottom: 5px;
    }

    .btn-calculate, .apply-link {
        @extend %Universal_45;
        background-color: #52ae32;
        border: solid 1px white;
        display: block;
        padding: 5px 10px;
        width: 100%;
        font-size: 16px;
        color: white;
        text-align: center;
        outline: none;

        &:hover {
            background-color: #00000b;
        }

        &:focus {
            outline: none;
        }
    }

    //Results block
    .loan-results-block {
        padding: 10px;
        font-size: 16px;

        .title {
            @extend %Universal_65;
        }

        .inner {
            padding: 15px;            
            line-height: 24px;
            background-color: $offWhite;
        }

        .results {
            > div {
                margin-bottom: 20px;
            }

            .total-payments {
                padding-top: 26px;
                border-top: 1px solid $table-border-color;
            }
        }

        .label {
            @extend %Universal_65;
            white-space: nowrap;
        }
    }

    .disclaimer-text {
        padding: 15px 10px 0 10px;

        p {
            @extend %Universal_45;
            font-size: 12px;
            color: black;
        }
    }

    //Desktop
    @media #{$sm} {
        .loan-results-block {

            .results > div {
                @include clearfix;
            }

            .label {
                float: left;
            }

            .value {
                float: right;
                margin-left: 15px;
                text-align: right;
            }
        }

        .btn-calculate {
            width: auto;
            display: inline-block;
        }

        .disclaimer-text {
            padding: 15px 0 0 0;
        }
    }
}

/**
*
* Styles for mobile off-canvas nav ONLY
* This is a cloned element, so is seperate from
* desktop + large screen Nav
*
**/
#mobile-nav {
    background-color: #283338;
    //MMenu plugin overrides
    .mm-listview > li:not(.mm-divider):after {
        display: none;
        border: none;
    }
    //Efic direct link
    .btn-efic-direct {
        @extend %Universal_45;
        display: block;
        background-color: $green;
        padding: 30px 20px;
        font-size: 16px;
        line-height: 1;
        color: white;
        border-bottom: 1px solid $green;
    }
    // Override the default trigger styles.
    // specificity due to over specific styles elsewhere
    li.direct {
        background-color: $green;

        & .mm-next,
        & li.mm-opened .mm-next {
            background-color: inherit !important;
            border-bottom: none !important;
        }

        a.primary-link {
            padding: 0;
            font-size: 16px;
            line-height: 29px;
            color: white;
            border-bottom: none;
            white-space: initial;
        }
    }

    .primary-link {
        @extend %Universal_45;
        display: block;
        background-color: #283338;
        padding: 30px 20px;
        font-size: 16px;
        line-height: 1;
        color: white;
        border-bottom: 1px solid #1e1e1e;
    }
    //Acordion trigger - arrow to right of primary link
    .mm-next {
        background-color: #283338;
        height: 77px;
        border-bottom: 1px solid #1e1e1e;

        &:after {
            position: absolute;
            top: 55%;
            right: 18px;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
            -ms-transform: translateY(-50%); /* IE 9 */
            transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
            content: "\62";
            color: white;
            font-size: 30px;
            font-family: "efic" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    //Secondary navs
    .sub-nav {
        background-color: #1b2326;
    }

    .sub-nav-items {
        padding: 25px 30px;

        li {
            margin-bottom: 5px;
        }

        a {
            //@extend %Universal_45;
            //display: block;
            //background-color: #283338;
            padding: 0;
            font-size: 16px;
            line-height: 29px;
            color: white;
            border-bottom: none;
            white-space: initial;
        }
    }
    //Opened menu item
    li.mm-opened {
        .primary-link {
            background-color: #1b2326;
            border-bottom: solid 1px #283338;
            color: $green;
        }

        .mm-next {
            background-color: #1b2326;
            border-bottom: solid 1px #283338;
            //Switch to up arrow
            &:after {
                content: "\6f";
            }
        }
    }
}

//Site overlay
/*
#mm-blocker {
    background-color: rgba(0,0,0,0.7);
}
*/

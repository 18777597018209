/**
*
* Global Primary Navigation
*
*
**/

$navHeight_med: 94px;

#primary-nav {
    display: none; //Hidden for small screens. See _mobile-nav.scss

    .primary-list {
        @include clearfix;
    }

    .btn-efic-direct {
        display: none;
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
        @include clearfix;
        display: block;
        width: 100%;
        padding-left: 15px;

        .primary-list {
            display: block;

            li {
                display: block;
                position: relative;
                float: left;
                max-width: 20%;
            }
        }


        //First level nav items
        .primary-link {
            @include animate-custom(color, background);
            display: table;
            height: $navHeight_med;
            width: auto;
            padding: 0 14px;
            border-left: 1px solid #d7dbdd;
            font-family: $universalLight;
            font-weight: bold;
            font-size: 16px;
            color: #2c3436;


            .text-wrap {
                display: table-cell;
                width: 100%;
                height: 100%;
                text-align: center;
                vertical-align: middle;
            }

            &.active {
                background-color: #f7f7f7;
                color: $green;
                text-decoration: none;
            }
        }


        //Subnav dropdowns
        .sub-nav {
            display: none;
            opacity: 0;
            position: absolute;
            left: 50%;
            top: $navHeight_med;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
            -ms-transform: translateX(-50%); /* IE 9 */
            transform: translateX(-50%); /* IE 10, Fx 16+, Op 12.1+ */
            z-index: 150;
            width: 185px;
            padding: 20px 20px 5px 20px;
            background-color: white;
            border: 1px solid #d7dbdd;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

            //Psuedo element on child UL for arrow
            ul {
                position: relative;

                &:before {
                    content: '';
                    background-image:url("#{$img-url}/sub-nav_arrow.png");
                    background-repeat: no-repeat;
                    display: block;
                    width: 24px;
                    height: 13px;
                    position: absolute;
                    top: -33px;
                    left: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                    -ms-transform: translateX(-50%); /* IE 9 */
                    transform: translateX(-50%); /* IE 10, Fx 16+, Op 12.1+ */
                }
            }

            li {
                width: 100%;
                max-width: 100%;
                margin-bottom: 10px;
            }

            a {

                border-right: none !important;
                font-family: $universalLight;
                font-size: 16px;
                color: #30383b;
                line-height: 22px;

                &:hover {
                    color: $green;
                    text-decoration: none;
                }
            }
        }
    }


    @media #{$md} {
        //MIN - 992
        .primary-list {
            li {
                max-width: 20%;

                //Last link needs a border
                &:last-child {
                    a {
                        border-right: 1px solid #d7dbdd;
                    }
                }
            }
        }

        .sub-nav {
            li {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

/* EFIC > Tiles > Video Tile | Deepend.com.au (c) 2015 */

.mod-video {

    height: 315px;

    > .inner {
        height: 100%;
    }

    .hd {

        float: right;
        width: 40%;
        text-align: center;
        position: relative;
        height: 100%;
        box-sizing: border-box;

        .desc {

            position: absolute;
            bottom: 0;
            min-height: 120px;
            margin: 0;
            padding: 10px 20px;
            color: $white;
            background: $darkGrey;

            > * {
                color: inherit;
            }
        }
    }

    .bd {
        float: left;
        width: 60%;
        position: relative;
        height: 100%;
    }
}

.colwide12 {

    .mod-video {
        margin-left: 10px;
        margin-right: 8px;
    }
}
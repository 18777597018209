/**
*
* Only apply to SBEL Apply Step page at moment
*
**/
.product-apply-steps {

    .step-duration p {
        @extend %Universal_65;
    }

    .step-description {
        display: block;
    }

    .step-thumb img {
        width: 48px;
        height: 48px;
    }
    //Desktop
    @media #{$sm} {
        //MIN - 768
        .step-description {
            display: inline-block;
            width: 65%;
        }

        .step-thumb img {
            width: 70px;
            height: 70px;
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}

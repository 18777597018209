/**
*
* table content styles
* Only apply to SBEL pages at moment
*
**/

.table {
    border: 1px solid $table-border-color;

    thead {
        @extend %Universal_65;

        > tr {
            > th {
                border-bottom: 1px solid $table-border-color;
            }
        }
    }

    td.heading {
        @extend %Universal_65;
        font-size: 16px;
    }

    th {
        background-color: $offWhite;

        &.alt-bg {
            background-color: $white;
        }

        &:not(:first-child) {
            border-left: 1px solid $table-border-color;
        }
    }

    td {
        background-color: $white;

        &.alt-bg {
            background-color: $offWhite;
        }
        
        &:not(:first-child) {
            border-left: 1px solid $table-border-color;
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768

        margin-bottom: 50px;

        > thead, > tfoot, > tbody {
            > tr > th,
            > tr > td {
                padding: 25px;
            }
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}

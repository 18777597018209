/**
*
* Reusuable gradient functions
*
**/
%gradient_45{
	//Note: hard dump from this tool:
	// http://www.cssmatic.com/gradient-generator
	background: rgba(93,177,45,1);
	/* Old Browsers */background: -moz-linear-gradient(45deg, rgba(93,177,45,1) 0%, rgba(172,200,12,1) 100%);
	 /* FF3.6+ */background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(93,177,45,1)), color-stop(100%, rgba(172,200,12,1)));
	/* Chrome, Safari4+ */background: -webkit-linear-gradient(45deg, rgba(93,177,45,1) 0%, rgba(172,200,12,1) 100%);
	 /* Chrome10+,Safari5.1+ */background: -o-linear-gradient(45deg, rgba(93,177,45,1) 0%, rgba(172,200,12,1) 100%);
	 /* Opera 11.10+ */background: -ms-linear-gradient(45deg, rgba(93,177,45,1) 0%, rgba(172,200,12,1) 100%);
	 /* IE 10+ */background: linear-gradient(45deg, rgba(93,177,45,1) 0%, rgba(172,200,12,1) 100%);
	/* W3C */filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5db12d', endColorstr='#acc80c', GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
}

%gradient_90 {
	background: rgba(85,174,49,1);
	background: -moz-linear-gradient(left, rgba(85,174,49,1) 0%, rgba(166,200,14,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(85,174,49,1)), color-stop(100%, rgba(166,200,14,1)));
	background: -webkit-linear-gradient(left, rgba(85,174,49,1) 0%, rgba(166,200,14,1) 100%);
	background: -o-linear-gradient(left, rgba(85,174,49,1) 0%, rgba(166,200,14,1) 100%);
	background: -ms-linear-gradient(left, rgba(85,174,49,1) 0%, rgba(166,200,14,1) 100%);
	background: linear-gradient(to right, rgba(85,174,49,1) 0%, rgba(166,200,14,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55ae31', endColorstr='#a6c80e', GradientType=1 );
}
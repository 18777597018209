/* EFIC > Borders | Deepend.com.au (c) 2015 | Author: Ray Ranola */

.bdr-grey {
    border: 1px solid $grey;
}

.bdr-grey-l {
    border-left: 1px solid $grey;
}

.bdr-grey-r {
    border-right: 1px solid $grey;
}

.bdr-grey-t {
    border-top: 1px solid $grey;
}

.bdr-grey-b {
    border-bottom: 1px solid $grey;
}
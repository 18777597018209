/**
*
* Brading variables - colours, logos etc
*
**/

$black:         #000;
$white:         #fff;
$offWhite:		#f3f3f3;

$green:         #52ae32;
$lightGreen:    #9fc511;
$grey:          #d7dbdd;
$darkGrey:      #30383b;

$altGrey:		#9aa1a7;
$hoverGrey:		#5d6569;

/* Social */
$facebook: #3b5998;
$twitter: #00aced;
$linkedIn: #3f729b;
$youTube: #bb0000;
$instagram: #3f729b;
$google: #dc4a38;
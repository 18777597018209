/**
*
* Tertiary Nav - search and contact us buttons
* Available for mobile ONLY
*
**/

#tertiary-nav {

    //Tertiary mobile nav items, call + search button
    .tertiary-list {
        display: block;

        li {
            display: block;
            position: relative;
            float: left;
            width: 50%;
        }

        a {
            display: block;
            width: 100%;
            height: 73px;
            padding-top: 18px;
            text-align: center;
            border-left: 1px solid #d7dbdd;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            .copy {
                display: none;
            }

            .icon {
                font-size: 39px;
                color: $green;

                &.icon-call {
                    display: inline-block;
                    padding-top: 8px;
                    font-size: 22px;
                }
            }
        }

        .btn-search-trigger {
            .icon-close {
                color: black;
                display: none;
            }

            &.active {
                .icon-close {display: block;}
                .icon-search{display: none;}
            }
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
        .tertiary-list {
            display: none;
        }
    }
}

/**
*
* Product a feature
* Only apply to SBEL page at moment
*
**/
.product-hero {
    @extend .jumbotron;
    position: relative;
    margin-bottom: 0;
    padding: 0;
    min-height: 200px;
    overflow: hidden;

    h1 {
        margin-top: 0;
        margin-bottom: 26px;
        color: $white;
        font-size: 36px;
        line-height: 40px;
        text-align: left;
        font-family: $decimaLight;
    }

    p {
        display: none;
        margin-top: 0;
        color: $white;
        font-size: 16px;
        text-align: left;
    }

    .img-block {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        height: 100%;
        overflow: hidden;
        transform: translate(-50%, -50%);
        z-index: 0;

        picture {
            height: 100%;
        }

        img {
            max-width: none;
            height: 100%;
        }
    }

    .container-fluid {
        position: relative;
        padding: 65px 20px;
        z-index: 1;
    }

    .btn-cta {
        min-width: 190px;
        text-align: center;

        &:not(:first-child) {
            margin-top: 20px;
            background-color: $altGrey;
        }

        &:hover {
            background-color: $hoverGrey;
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
        min-height: 290px;

        .container-fluid {
            padding: 105px 0 92px;
        }

        h1 {
            margin-bottom: 22px;
            font-size: 50px;
            line-height: 1;
        }

        p {
            display: block;
        }

        .img-block {
            width: 100%;
            height: auto;

            picture, img {
                width: 100%;
                height: auto;
            }
        }

        .cta-block {
            margin-top: 40px;
        }

        .btn-cta {
            &:first-child {
                margin-right: 16px;
            }

            &:not(:first-child) {
                margin-top: 0;
            }
        }
    }

    @media #{$md} {
        //MIN - 992
        margin-bottom: 40px;
    }
}

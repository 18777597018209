/**
*
* Directory variables
*
**/
$static-url: '/static/';
$img-url: $static-url +'img/';
$font-url: $static-url + 'fonts/'; //Temp only


/**
*
* Overriding Bootstrap config variables
*
**/

$link-color: #53ae31 !default;
$link-hover-color: #30383b !default;
$link-hover-decoration: none !default;

/**
*
* Structure, Media queries and breakpoints
*
**/
$max-site-width: 1400px;
$max-content-width: 960px;

$grid-columns:              12 !default;
$grid-gutter-width:         24px;


// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs  !default;
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm  !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

//Media queries based on above vars
$xs: "only screen and (min-width : "$screen-xs")";
$sm: "only screen and (min-width : "$screen-sm")";
$md: "only screen and (min-width : "$screen-md")";
$lg: "only screen and (min-width : "$screen-lg")";

/**
*
* FAQ page
*
**/

.faq {

    .faq-category {
        margin: 15px auto 20px;

        h3 {
            font-size: 22px;
        }
    }

    .accordion .item-heading  {
        h4 {
            font-size: 18px;
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768

        .faq-category {
            h3 {
                font-size: 32px;
            }
        }

        .accordion .item-heading  {
            h4 {
                font-size: 24px;
            }
        }
    }

    @media #{$md} {
        //MIN - 992
    }
}

/**
*
* Fullwidth banner, with image and text
* Bottom of product page
*
**/
.fullwidth-banner {

    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1600px;
    height: 200px;
    margin: auto;
    margin-top: 30px;

    .img {
        position: relative;
        z-index: 5;

        height: 100%;
        width: 100%;
        text-align: center;
    }

    /*img {
        position: absolute;
        height: 100%;
        left: 50%;
        top: 0;
        margin-left: -800px;
    }*/

    .content {
        display: table;
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;

        .inner-wrap {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
        }
    }

    p {
        @extend %Universal_45;

        font-size: 22px;
        color: #fff;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
    }

    a {
        @extend %Universal_65;
        font-size: 22px;
        color: #fff;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        text-decoration: underline;
    }

    .icon-wrap {
        display: block;
        width: auto;
        margin: auto;

        .icon {
            display: inline-block;
            height: 60px;
            font-size: 85px;
            color: white;
        }
    }
}

﻿// Default responsive picture and img styles.

picture {
    display: block;
    text-align: center;

    img {
        vertical-align: middle;
        max-width: 100%;
    }
}

.nested-figure {
    margin: 1em 0;
}

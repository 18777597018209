//Styling for the call to action tiles on home page


.cta-tile-container{
    .cta-tile{
        background-color: #000;
        background-size: cover;
        height: 200px;

        background-color: darkgray;
        margin-bottom: 24px;

        //gray overlay for image and hover effect
        .cta-background-overlay{
            width:100%;
            height: 100%;
            background: rgba(20,20,20,.3);
            -webkit-transition: background 0.5s; /* For Safari 3.1 to 6.0 */
        }

        .btn-cta{
            -webkit-transition: background 0.5s; /* For Safari 3.1 to 6.0 */
            -webkit-transition: color 0.5s; /* For Safari 3.1 to 6.0 */
        }

        .cta-background-overlay:hover{
            background: rgba(20,20,20,.15);

            .btn-cta{
                background: white;
                color:#5EB33A;
            }
        }


        .cta-tile-content{
            padding: 15px;
            width: 65%;

            @media screen and (max-width: $screen-md) {
                width: 80%;
            }

            h4{
                font-size: 21px;
                font-weight: 700;
                margin-top:0;
                margin-bottom: 25px;
                color:white;
            }
            .btn-cta{

                border: 0;
            }
        }
    }
    .cta-btn{
        border: 0 !important;
    }
    margin-bottom: 0;
}

/**
*
* Simple, right hand side promo tiles
*
**/

.side-promo-tile {

    /*GUIDES*/
    /*/
    background-image:url("#{$img-url}/_GUIDES/ad-tile_large.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    height: 320px;
    /**/

    position: relative;
    margin-bottom: 20px;

    .inner {
        display: table;
        width: 100%;
        height: 100%;
        padding: 20px;
        border: 1px solid #d7dbdd;
        background-color: white;
    }

    .img {
        position: absolute;
        border-radius: 68px;
        width: 136px;
        height: 136px;
        overflow: hidden;
        left: 50%;
        top: 8px;
        margin-left: -68px;
    }

    .content-block {
        display: table-cell;
        width: 100%;
        height: 100%;
        padding: 20px;
        vertical-align: bottom;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a8c80d+1,58b02f+100 */
        background: #a8c80d; /* Old browsers */
        background: -moz-linear-gradient(45deg,  #a8c80d 1%, #58b02f 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg,  #a8c80d 1%,#58b02f 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg,  #a8c80d 1%,#58b02f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8c80d', endColorstr='#58b02f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

        border: 1px solid #d7dbdd;
    }


    .title {
        @extend %Universal_65;
        margin-top: 0;
        font-size: 22px;
        color: white;
        line-height: 1.3
    }

    .content {
        @extend %Universal_45;
        margin-top: 0;
        font-size: 16px;
        font-weight: bold;
        color: white;
    }

    .cta {
        @extend %Universal_45;
        display: block;
        margin: 0;
        padding: 5px;
        font-size: 16px;
        color: white;
        text-align: center;
        background-color: #52ae32;
    }

    //With image
    &.has-image {
        padding-top: 40px;

        .content-block {
            padding-top: 100px;
        }
    }

    //Desktop
    @media #{$sm} {

        height: 282px;
        padding-top: 40px;
    }

    @media #{$md} {

        height: auto;
        padding-top: 0px;
    }
}

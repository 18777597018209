/* EFIC > Modules > Social | Deepend.com.au (c) 2015 | Author: @elray72 */

$shareIconSize: 35px;
$connectIconSize: 30px;
$fadeDuration: 0.2s;

.mod-social-share {
    margin: 20px 0;
    height: $shareIconSize;
    display: inline-block;

    .label {
        float: left;
        font-weight: bold;
        line-height: $shareIconSize;
        margin-right: 0.8em;
    }

    .list {
        float: left;
        margin: 0;

        .item {
            border: none;
            overflow: hidden;
            padding: 0;

            .icon {
                color: $white;
                background: $grey;
                height: $shareIconSize;
                width: $shareIconSize;
                margin-right: 4px;
                display: block;
                float: left;
                line-height: $shareIconSize;
                font-size: 24px;
                text-align: center;
                -webkit-transition: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;
                -ms-duration: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;
                -o-duration: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;
                transition: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;

                &.icon-twitter:hover {
                    background: $twitter;
                }

                &.icon-facebook:hover {
                    background: $facebook;
                }

                &.icon-google-plus:hover {
                    background: $google;
                }

                &.icon-linked-in:hover {
                    background: $linkedIn;
                }

                &.icon-email:hover {
                    background: darken($grey, 40%);
                }

                &:focus {
                    outline: none;
                }

                &:before {
                    position: relative;
                    top: 4px;
                }
            }
        }
    }
}

.mod-social-connect {
    @include clearfix;
    height: $connectIconSize;
    display: inline-block;

    .list {
        @include clearfix;
        // float: left;
        margin: 0;
        margin-top: 5px;

        .item {
            border: none;
            overflow: hidden;
            padding: 0;
            background: transparent !important;

            .icon {
                color: $grey !important;
                background: transparent;
                height: $connectIconSize;
                width: $connectIconSize;
                margin-right: 4px;
                display: block;
                float: left;
                line-height: $connectIconSize;
                font-size: ($connectIconSize / 1.75);
                text-align: center;
                -webkit-transition: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;
                -ms-duration: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;
                -o-duration: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;
                transition: border $fadeDuration linear, color $fadeDuration linear, background-color $fadeDuration linear;

                &.icon-twitter {
                    background: $twitter;

                    &:hover {
                        background: darken($twitter, 20%);
                    }
                }

                &.icon-facebook {
                    background: $facebook;

                    &:hover {
                        background: darken($facebook, 20%);
                    }
                }

                &.icon-google {
                    background: $google;

                    &:hover {
                        background: darken($google, 20%);
                    }
                }

                &.icon-linkedin {
                    background: $linkedIn;

                    &:hover {
                        background: darken($linkedIn, 20%);
                    }
                }

                &.icon-youtube {
                    background: $youTube;

                    &:hover {
                        background: darken($youTube, 10%);
                    }
                }

                &.icon-email {
                    background: darken($grey, 40%);

                    &:hover {
                        background: lighten($grey, 20%);
                    }
                }
            }
        }
    }
}

.article,
.news-head {

    .mod-share {
        border-top: 1px solid $grey;
        width: 100%;
        display: block;
        padding-top: 10px;
        padding-left: 0px;
        margin-top: 30px;
    }

    &:not(.article) {
        .mod-share {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: $screen-xs-max) {

    .mod-social-connect {
        height: $connectIconSize + 5;
        display: inline-block;
        overflow: hidden;

        .label {
            float: left;
            margin-right: 10px !important;
            margin-top: 15px !important;
            line-height: $connectIconSize;
        }
    }
}

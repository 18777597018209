/**
*
these are two table styles that allow either the new styles or or old (green table style)
*
**/

//GREEN TABLE STYLE
table {
      border-collapse: collapse;
      a {
          text-decoration:underline;
      }
  }
    td {
      border: solid 1px #52ae32;
      padding: 2px 5px;
    }
     td h3 {
      color: #52ae32;
    }
     td h4 {
      color: #52ae32;
    }
     td h1 {
      color: #52ae32;
    }

     tr:first-child {
      background-color: #EAF2D3;
    }
     tr:nth-child(odd) {
      background-color: #fafafa;
    }
     tr:first-child {
      background-color: #EAF2D3;
    }

     tr td h2 {
      color: #52ae32;
    }

//PLAIN BLACK AND WHITE TABLY STYLE
.secondary-table {
    border: 1px solid $table-border-color !important;

    th {
        @extend %Universal_65;
        padding: $table-cell-padding;
        background-color: $offWhite;
        border: 1px solid $table-border-color;

        h3, h4, h5, h6, p {
            @extend %Universal_65;
        }
    }

    tr {
        background-color:white;
    }
    a {

        text-decoration:underline;
        &:hover {
            color: #000000;
        }
    }

    td {
        padding: $table-cell-padding;
        border: 1px solid $table-border-color;
        background-color:white;
    }

    ul, ol {
        li {
            margin-bottom: 0;
        }
    }
    //Desktop
    @media #{$sm} {

            > thead, > tfoot, > tbody {
                > tr > th,
                > tr > td {
                    padding: 25px 25px 5px;
                }
            }

    }

}

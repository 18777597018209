/**
*
* Overlay module
*
*
**/

body.site-overlay-open {
    overflow: hidden;
}

#master-content > .site-overlay {
    opacity: 0;
    display: none;
    transition: opacity .15s ease;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .site-overlay-header {
        padding: 25px 20px;
        background-color: #F8F8F8;
        color: #2d3a41;

        .site-overlay-title {
            @extend %Universal_65;
            font-size: 16px;
            margin: 0;
            display: inline-block;
        }

        .close {
            float: right;
            background: none;
            font-size: 28px;
            border: none;
        }
    }
}

body.site-overlay-open #master-content > .site-overlay {
    opacity: 1;
}
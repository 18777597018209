﻿// Styles for the tooltip containing the alt attribute from article images.
.article img[alt] {
    //cursor: help;
}

.alt {
    display: block;
    border: 1px solid #55ae31;
    margin: 10px 0;
    padding: 10px;
}

/**
*
* Global master header
* Includes structure primarily
*
**/
#master-header {

    border-bottom: 1px solid $table-border-color;

    .logo-link {
        display: block;
        width: calc(100% - 62px);
        height: 100%;
        float: left;
        padding: 16px 36px;

        img { margin: auto;}

        //Hide / show logo image
        .small { display: block;}
        .med { display: none;}
    }

    .btn-mobile-menu {
        display: block;
        float: left;
        width: 62px;
        height: 73px;
        padding-top: 14px;
        text-align: center;
        border-right: 1px solid #d7dbdd;
        text-decoration: none;

        .icon {
            font-size: 46px;
            color: $green;
        }
    }

    //Desktop
    @media #{$sm} {
        //MIN - 768
        .logo-link {
            display: block;
            width: 100%;
            height: 100%;
            padding: 15px 0 0 0;

            //Hide / show logo image
            .small { display: none;}
            .med { display: block;}
        }

        .btn-mobile-menu {
            display: none;
        }

        &.pushback {
            .nav-row {
                position: fixed;
                width: 100%;
                top: 0;
                z-index: 500;
                margin-top: -94px;
                background-color: $body-bg;
                border-bottom: 1px solid $table-border-color;
                transition: margin-top $mm_transitionDuration $mm_transitionFunction;

                &.push-in {
                    margin-top: 0;
                }
            }
        }
    }
}

/* EFIC > Modules > Social | Deepend.com.au (c) 2015 | Author: @elray72 */

#regionIframe {
    width: 100%;
    margin-bottom: 20px;
}

.back-to-country-view {
    color: $green;
    text-decoration: underline;
    cursor: pointer;
}
/**
*
* Popup Notification
* Only apply to SBEL pages at moment
*
**/

#popup-notification {
	.modal-dialog {
		margin: 20px auto;
		width: 300px;
		text-align: center;
	}
	.modal-title {
		@extend %Universal_65;
		color: $black;
	}
	.modal-footer {
		text-align: center;		
	}
}
/**
*
* Page Breadcrumbs
*
Parent DIV - NOTE: Not all templates have list wrapped in <div class="breadcrumb">, hence styling
Is kept seperate

**/

.breadcrumbs {
    display: flex;

    .back-to-search {
        margin: 30px 20px 0 0;
        padding-right: 20px;
        border-right: 1px solid #DCDBCF;
        color: #2c3436;

        a {
            text-decoration: underline;
            color: #2c3436;
        }
    }
}

ul.breadcrumb {
    @include clearfix();
    padding: 0 0 20px 0;
    margin: 30px 0 0;
    li {
        list-style-type: none;
        float: left;
        color: black;
    }

    a {
        @extend %Universal_45;
        font-size: 15px;
        color: $green;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .divider {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        color: green;
    }
}

/**
*
* Mobile only breadcrumb
* Note: this is a copy / paste from legacy style, from original _header.scss
**/

.mobile-breadcrumb {

    color: #fff;
    position: relative;

    li {

        display: none;
        height: 35px;
        border-bottom: 1px solid lighten($darkGrey, 40%);
        background: $grey;
        color: $darkGrey;

        a {
            display: block;
            line-height: 35px;
            padding: 0 15px;
            padding-left: 20px;
            color: inherit;
        }

        &.btn-expand {
            display: block;
            width: 35px;
            background: lighten($darkGrey, 20%);
            position: absolute;right: 0;
            bottom: 0;
            line-height: 35px;
            text-align: center;
            cursor: pointer;
            color: #fff;
            font-weight: bold;
        }

        &:last-of-type {
            display: block;
            background: $darkGrey;
            margin-bottom: -1px;

            a {
                color: #fff;
            }
        }
    }

    &.open {
        li {
            display: block;
        }
    }
}
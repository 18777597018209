.contour.downloadform {

    background: #eee;
    padding: 15px 20px;
    margin: 25px 0;

    .contourField {
        height: 43px;
    }


    .contourFieldSet {
        padding: 0;
    }

    label.fieldLabel {
        width: 215px;
        padding-right: 20px;
        margin-top: 12px;

        .contourIndicator {
            color: red;
        }
    }

    select {
        height: 30px;
        line-height: 31px;
        width: 297px;
    }

    .hiddenfield {
        display: none;
    }

    .contourMessageOnSubmit
    input.text,
    textarea,
    input.text,
    textarea {
        width: 285px;
        height: 26px;
        line-height: 31px;
    }

    .contact-form-button {
        height: 35px;
        line-height: 35px;
        padding: 0 !important;
        background: #52ae32;
        a {
            height: inherit;
            line-height: inherit;
            padding: inherit;
            text-decoration: none;
            p {
                height: inherit;
                line-height: inherit;
                padding: inherit;
                margin: 0;
            }
        }
    }
}